<template>
  <div
    id="site-upload-form"
    :style="(type != undefined && isMobileDevice() == false) ? type.customStyle: ''"
  >
    <div class="breadcrumbs">
      <div class="breadcrumbs-left">
        <ul>
          <li v-if="this.type !=  undefined">
            <span v-if="$route.params.operation == 'addentity'">Create New {{type.displayName}}</span>
            <span v-if="$route.params.operation == 'edit'">Edit {{type.displayName}}</span>
          </li>
        </ul>
      </div>
      <div class="breadcrumbs-right"></div>
    </div>

    <div id="create-site-layer" v-if="this.type !=  undefined">
      <form>
        <div>
          <div v-for="field in formFields" :key="field.name">
            <div v-if="field.type == 'id'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>
              <div>{{field.value}}</div>
            </div>

            <div v-if="field.type == 'date'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>
              <span class="required" v-if="field.requiredField">*</span>
              <datepicker :format="'dd/MM/yyyy'" placeholder="Select Date" v-model="field.value"></datepicker>
            </div>

            <div v-if="field.type == 'string' || field.type == 'geojsonlarge'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>
              <span class="required" v-if="field.requiredField">*</span>
              <input :id="field.name" type="text" v-model="field.value" />
            </div>

            <div v-if="field.type == 'video'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>
              <div class="input-field--nested">
                <label>Url</label>
                <input :id="field.name" type="text" v-model="field.value.url" />
              </div>
              <div class="input-field--nested">
                <label>Auto Play</label>
                <input :id="field.name+'-autoplay'" type="checkbox" v-model="field.value.autoplay" />
              </div>
            </div>

            <!-- <div v-if="field.type == 'readonly'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>
              <input readonly :id="field.name" type="text" v-model="field.value" />
              <input
                readonly
                :id="field.name"
                type="text"
                :model="parentLink(field.value)"
                v-if="field.name=='parent'"
              />
            </div>-->
            <!-- <div v-if="field.type == 'parentLink'" class="input-field">
              <strong>Parent:</strong> &nbsp;
              <router-link :to="parentLink(field.value,'Link')">{{parentLink(parent,'Parent')}}</router-link>
            </div>-->
            <div v-if="field.type == 'hidden'" class="input-field" style="display:none">
              <input readonly :id="field.name" type="hidden" v-model="field.value" />
            </div>

            <div v-if="field.type == 'password'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>
              <span class="required" v-if="field.requiredField">*</span>
              <input :id="field.name" type="password" v-model="field.value" />
            </div>

            <div v-if="field.type == 'dropdown'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>
              <span class="required" v-if="field.requiredField">*</span>
              <select class="browser-default" v-model="field.value">
                <option
                  v-for="option in field.values"
                  :key="option.index"
                  :value="option.value"
                >{{option.name}}</option>
              </select>
            </div>

            <!-- NEW CODE STARTS -->
            <div v-if="field.type == 'rank'" class="input-field">
              <label class="rankLabel" :for="field.name">{{field.displayName}}</label>
              <div class="rank">
                <div>{{field.values.minDesc}}</div>
                <div>{{field.values.maxDesc}}</div>
              </div>

              <vue-range
                class="rankBar"
                :bar-height="3"
                :step="1"
                :min="field.values.minValue"
                :max="field.values.maxValue"
                v-model="field.value"
                :handleValue="field.value"
              >
                <div class="rankStart" slot="start">{{field.values.minValue}}</div>
                <div class="rankEnd" slot="end">{{field.values.maxValue}}</div>
              </vue-range>
            </div>

            <div v-if="field.type == 'checkbox'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>
              <span class="required" v-if="field.requiredField">*</span>
              <span class="helper-icon" v-if="field.helpField">
                <i class="material-icons" @click="toggleHelper(field.name)">info_outline</i>
              </span>

              <div class="helper-msg" :ref="field.name" v-if="field.helpField">
                <div class="card-panel teal" v-html="field.helpField"></div>
              </div>

              <div :id="field.name  + '_check'" class="checkbox t_caption">
                <label class="checkbox-container" v-for="(item) in field.values" v-bind:key="item.value">
                  {{item.name}}
                  <input
                    type="checkbox"
                    :id="item.value + '_check'"
                    :value="item.value"
                    v-model="field.value"
                  />
                  <span class="checkmark" :id="item.value"></span>
                </label>
              </div>
            </div>

            <div v-if="field.type == 'radio'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>
              <span class="required" v-if="field.requiredField">*</span>
              <div class="radio t_caption">
                <label v-for="(item, index) in field.values" v-bind:key="index" class="container">
                  {{item.name}}
                  <input
                    type="radio"
                    :value="item.value"
                    class="propertyLand"
                    :id="item.value"
                    v-model="field.value"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>

            <div v-if="field.type == 'label'" class="input-field">
              <label :for="field.name" v-html="field.displayName"></label>
            </div>
            <!-- NEW CODE ENDS -->
             <div v-if="field.type == 'yesno'" class="input-field">
              <label :for="field.name" v-html="field.displayName"></label>
              <input type="string"  v-model="field.value"/>
            </div>
            <div v-if="field.type == 'geojson'" class="input-field">
              <button
                v-if="!isIteractingMap(field.name)"
                @click.prevent="registerMapInteraction(field.name,field.type,field.values.options)"
              >{{field.displayName}}</button>
              <button
                v-if="isIteractingMap(field.name)"
                @click.prevent="deRegisterMapInteraction(field.name,field.type)"
              >Stop Edit</button>

              <Editable
                v-if="field.values != undefined ? field.values.showCode : true"
                :id="field.name"
                v-model="field.value"
              ></Editable>
            </div>

            
            <div v-if="field.type == 'json'" class="input-field">
              <Editable
                v-if="field.values != undefined ? field.values.showCode : true"
                :id="field.name"
                v-model="field.value"
              ></Editable>
            </div>

            <div v-if="field.type == 'color'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>

              <Chrome :id="field.name" v-model="field.value" />
              <!-- <input :id="field.name" type="text" v-model="field.value.hex" /> -->
            </div>

            <div v-if="field.type == 'textarea'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>
              <span class="required" v-if="field.requiredField">*</span>
              <textarea :id="field.name" type="text" v-model="field.value"></textarea>
            </div>
            
            <div v-if="field.type == 'entityLink'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>
              <div class="input-field--nested">
                <label :for="field.name+'-name'">Name</label>
                <input :id="field.name+'-name'" type="text" v-model="field.value.name" />
              </div>
              <div class="input-field--nested">
                <label :for="field.name + '-value'">Url</label>
                <input :id="field.name+ '-value'" type="text" v-model="field.value.url" />
              </div>
            </div>

            <div v-if="field.type == 'html'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>
              <textarea :id="field.name" type="text" v-model="field.value"></textarea>
            </div>

            <div v-if="field.type == 'files'" class="input-field">
              <label>{{field.displayName}}</label>
              <div id="fileListing">
                <table v-if="(field.value != undefined ? field.value : 0) != 0">
                  <thead>
                    <tr>
                      <th>Name</th>

                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item,i) in field.value" :key="i">
                      <td v-if="!item.uploaded">
                        <div class="uploader">
                          <span :title="item.name">{{item.name}}</span>
                          <span :style="'width:' + item.progress + '%'"></span>
                        </div>
                      </td>
                      <td v-if="item.uploaded">
                        <a :href="item.url" _target="blank">
                          <span :title="item.name">{{item.name}}</span>
                        </a>
                      </td>
                      <td v-if="item.uploaded" @click.prevent="removeFromlist(field.value,item)">
                        <i title="Click to delete" class="material-icons">delete</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="file-field input-field">
                  <div class="btn custom-btn">
                    <span>Click here to select files</span>
                    <input
                      @change="updateFileList(field.name)"
                      type="file"
                      :ref="field.name"
                      multiple
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="field.type == 'readonly'" class="input-field">
              <label :for="field.name">{{field.displayName}}</label>
              <input readonly :id="field.name" type="text" v-model="field.value" />
              <input
                readonly
                :id="field.name"
                type="text"
                :model="userLink(field.value)"
                v-if="field.name=='createdBy'"
              />
            </div>

            <div v-if="field.type == 'userLink'" class="input-field">
              <strong>Created By:</strong> &nbsp;
              <router-link :to="userLink(field.value,'Link')">{{userLink(field.value,'UserName')}}</router-link>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="bottom-fixed">
      <button
        v-if="deleteEnabled"
        class="deleteButton"
        :title="'Delete'"
        v-confirm="{
                  loader: true,
                  ok: dialog => deleteData(dialog),
                  cancel: doNothing,
                  message: 'Are you sure you want to delete?'}"
      >
        <i class="material-icons">delete</i>
      </button>
      <button @click.prevent="dismissUploadForm()" title="Cancel Editing" class="cancelButton">
        <i class="material-icons">clear</i>
      </button>
      <button @click.prevent="saveData('save')" title="Save" class="saveButton" v-if="!loading">
        <i class="material-icons">save</i>
      </button>
      <button title="Save" class="saveButton disabled" v-if="loading">
        <i class="material-icons">hourglass_full</i>
      </button>
      <!-- <img src="@/assets/images/loading.png" v-if="!loading" style="width: 30px;margin-left:20px;"> -->
      <button
        @click.prevent="saveData('submit')"
        v-show="showSubmit"
        v-if="canSubmit"
      >{{ nextStep }}</button>
    </div>

    <MapKey />
    <!-- <SatView /> -->
  </div>
</template>

<script>

import Settings from "@/mixins/AppSettings.js";
import { mapState, mapGetters } from "vuex";
import Apicall from "../mixins/Apicall";
import { Chrome } from "vue-color";
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
import Editable from "@/components/Editable";
import vueRange from "@/components/Range";
import firebase from "firebase";
import MapKey from "@/components/MapKey";
import SatView from "@/components/SateliteViewToggle";

export default {
  name: "EntityEdit",
  mixins: [],
  components: {
    Chrome,
    Datepicker,
    Editable,
    vueRange,
    MapKey,
    SatView
  },
  data() {
    return {
      type: undefined,
      loading: false,
      formFields: [],
      types: [],
      parent: this.$route.params.id,
      mapInteractions: [],
      listOfFiles: [],
      uploadList: [],
      uploadobj: {},
      deleteList: [],
      currentFileItem: undefined,
      deleteEnabled: false,
      showSubmit: false
    };
  },
  created() {},

  beforeDestroy() {},
  async mounted() {
    let results = await Apicall.getfirebaseCollectionAll("entityTypes");
    if (this.entities.length == 0) {
      await this.$store.dispatch("populateEntities");
    }

    //console.log("types", results);
    if (this.$route.params.operation) {
      if (this.$route.params.operation == "edit") {
        this.deleteEnabled = true;
        this.showSubmit = true;
      }
    }

    for (let item of results) {
      if (this.$route.params.type) {
        if (this.$route.params.type == item.type)
          this.addGroup(item, this.$route.params.operation);
      } else {
        this.types.push(item);
      }
    }

    this.$nextTick(() => {
      let selectElems = document.querySelectorAll("select");
      let selectInstances = M.FormSelect.init(selectElems, {});
    });
  },
  computed: {
    ...mapState(["entities"]),
    ...mapGetters([
      "entityByDocId",
      "entityByType",
      "entityParentByChild",
      "entityByParent",
      "entityTypeByType",
      "entityTypesByParentType",
      "user"
    ]),
    // reverseMessage: function (userEmail) {
    //   console.log('userEmail',userEmail);
    //   return userEmail;
    // },

    canSubmit() {
      let editEntity = this.entityByDocId(this.$route.params.id)[0];
      if (editEntity && this.type) {
        if (editEntity.state == "default") {
          return false;
        }

        if (this.type.states) {
          let currentState = this.type.states.find(item => {
            return item.name == editEntity.state;
          });

          return currentState.role.indexOf(this.user.role) != -1;
        } else {
          return false;
        }
      }
    },

    nextStep() {
      let currentStepObj = this.formFields.filter(
        obj => obj.displayName == "State"
      );
      if (
        currentStepObj.length > 0 &&
        currentStepObj[0].displayName == "State"
      ) {
        if (currentStepObj[0].value !== "default") {
          let currStep = this.type.states.filter(
            state => state.name == currentStepObj[0].value
          );
          // this.showSubmit = true;
          return currStep[0].submitDesc;
        } else {
          this.hideSubmit = false;
          return "Submit";
        }
      } else {
        this.hideSubmit = false;
        return "Submit";
      }
    }
  },
  methods: {
    toggleHelper(instance) {
      console.log(this.$refs[instance]);
      this.$refs[instance][0].classList.toggle("helper-msg--show");
    },
    parentLink: function(existingParentId, displayType) {
      var nextPage = "/" + "map/entity/";
      let parentId = this.$route.params.id;
      let parent = this.entities.filter(parent => {
        return parent.docId == parentId;
      });

      if (displayType == "Parent") {
        return parent[0].name;
      } else if (displayType == "Link") {
        return nextPage + parent[0].docId;
      }
    },
    userLink: function(userEmail, displayType) {
      let user = this.entities.filter(user => {
        return user.properties.email == userEmail;
      });
      var nextPage = "/" + "users/entity/";
      var back = "?" + "browseHistory=" + this.$route.params.type;
      if (displayType == "UserName") {
        return user[0].name;
      } else if (displayType == "Link") {
        return nextPage + user[0].docId + back;
      }
    },
    isMobileDevice() {
      if (window.innerWidth < 1366) {
        return true;
      } else {
        return false;
      }
    },
    updateHidden(field) {
      let editor = this.$refs[field + "-edit"];

      //let hiddenElement = document.getElementById(field);

      let hiddenElement = this.$refs[field];

      let hiddenFormField = this.formFields.find(item => {
        return item.name == field;
      });

      hiddenFormField.value = editor[0].innerText;
      // debugger
    },
    doNothing() {},
    updateFileList(name) {
      let files = this.$refs[name][0].files;

      let uploadList = [];

      let filesField = this.formFields.find(item => {
        return item.type == "files" && item.name == name;
      });

      if (filesField.value) {
        uploadList = filesField.value.filter(item => {
          return item.uploaded == true;
        });
      }

      // fileCount = uploadList.length

      let fileCount = 0;

      for (var file of files) {
        let uploadobj = {};

        uploadobj["name"] = file.name;
        uploadobj["type"] = file.type;
        uploadobj["url"] = "";
        uploadobj["progress"] = 0;
        uploadobj.uploaded = false;
        uploadobj.fileIndex = fileCount;
        uploadList.push(uploadobj);

        fileCount++;
      }

      filesField.value = uploadList;
    },
    uploadFile(file, resolve, reject) {
      var self = this;
      const name = +new Date() + "-" + file.name;
      const metadata = { contentType: file.type };
      const ref = firebase.storage().ref();
      const fileref = ref.child(name);
      const task = ref.child(name).put(file, metadata);

      task.on(
        "state_changed",
        function(snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");

          self.currentFileItem.progress = Math.round(progress);
          //uploadProgress.style.width = Math.round(progress * 2) + 'px';
        },
        function(error) {
          // Handle unsuccessful uploads
        },
        function() {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            //console.log(downloadURL);

            self.currentFileItem.uploaded = true;
            self.currentFileItem.url = downloadURL;
            self.processUploadList(resolve, reject);
          });
        }
      );
    },
    async startProcessList() {
      return new Promise((resolve, reject) => {
        this.processUploadList(resolve, reject);
      });
    },

    processUploadList(resolve, reject) {
      let filesFields = this.formFields.filter(item => {
        return item.type == "files";
      });

      if (filesFields.length == 0) {
        resolve();
      }

      for (let fileField of filesFields) {
        if (fileField.value) {
          this.currentFileItem = fileField.value.find(item => {
            return item.uploaded == false;
          });
        }

        if (this.currentFileItem != undefined) {
          let file = this.$refs[fileField.name][0].files[
            this.currentFileItem.fileIndex
          ];
          this.uploadFile(file, resolve, reject);
        } else {
          resolve();
        }
      }
    },
    removeFromlist(files, item) {
      //debugger
      this.deleteList.push({ url: files[item.fileIndex].url });
      files.splice(item.fileIndex, 1);
      M.toast({
        html: "File removed, Please submit to update.",
        classes: "rounded"
      });
    },
    isIteractingMap(eventSource, type) {
      return this.mapInteractions.find(item => {
        return item.eventSource == eventSource;
      });
    },
    deRegisterMapInteraction(eventSource, type) {
      this.mapInteractions.pop({ eventSource: eventSource, type: type });

      let updateMap = true;
      this.$root.$emit("refreshMap", this.mapInteractions, updateMap);
      this.$root.$off(eventSource, this.handleRefreshCoords);
    },
    registerMapInteraction(eventSource, type, options) {
      let drawOptions = {};
      if (options) {
        drawOptions = JSON.parse(options);
      }

      this.mapInteractions.push({ eventSource: eventSource, type: type });
      let updateMap = true;
      this.$root.$emit(
        "refreshMap",
        this.mapInteractions,
        updateMap,
        drawOptions
      );
      this.$root.$on(eventSource, this.handleRefreshCoords);
    },
    handleRefreshCoords(eventSource, updatedGeoJson) {
      let geoJsonField = this.formFields.find(item => {
        return item.type == "geojson" && item.name == eventSource;
      });

      for (let feature of updatedGeoJson.features) {
        let updatedProperties = {};
        for (let property in feature.properties) {
          if (
            property != "docId" &&
            property != "name" &&
            property != "parent" &&
            property != "type" &&
            property != "show" &&
            property != "children"
          ) {
            updatedProperties[property] = feature.properties[property];
          }
        }

        feature.properties = updatedProperties;
      }

      geoJsonField.value = JSON.stringify(updatedGeoJson, null, 4);
    },
    string_to_slug(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaeeeeiiiioooouuuunc------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
    addGroup(type, operation) {
      this.type = type;

      this.formFields.push({
        order: 1,
        name: "name",
        type: "string",
        value: undefined,
        displayName: "Name",
        requiredField: true
      });

      this.formFields.push({
        order: -1,
        name: "state",
        type: "hidden",
        value: undefined,
        displayName: "State",
        requiredField: true
      });

      this.formFields.push({
        order: 999,
        name: "createdBy",
        type: "userLink",
        value: undefined,
        displayName: "Created By",
        requiredField: true
      });
      // this.formFields.push({
      //   order: 3,
      //   name: "parent",
      //   type: "parentLink",
      //   value: this.$route.params.id,
      //   displayName: "Parent Site",
      //     requiredField: true
      // });

      if (operation == "addentity") {
        let defaultState = "default";
        if (type.states) {
          defaultState = type.states[0].name;
        }

        this.formFields.find(item => {
          return item.name == "state";
        }).value = defaultState;

        this.formFields.find(item => {
          return item.name == "createdBy";
        }).value = this.user.email;
      }

      let editEntity = {};
      if (operation == "edit") {
        editEntity = this.entityByDocId(this.$route.params.id)[0];

        if (editEntity.parent != "null" && Settings.parentChange) {
          let parentItem = this.entityByDocId(editEntity.parent)[0];

          let parentTypes = this.entityTypesByParentType(
            parentItem.type
          ).filter(item => {
            return item.type != editEntity.type;
          });
          let combinedParents = [];
          combinedParents = combinedParents.concat(
            this.entityByType(parentItem.type)
          );
          combinedParents = _.orderBy(combinedParents, "name");

          for (let parentType of parentTypes) {
            let foundParents = this.entityByType(parentType.type);
            combinedParents = combinedParents.concat(foundParents);
          }

          let listItems = [];
          for (let item of combinedParents) {
            listItems.push({ name: item.name, value: item.docId });
          }

          listItems = _.orderBy(listItems, "name");

          this.formFields.push({
            order: -1,
            name: "parent",
            type: "dropdown",
            value: editEntity.parent,
            values: listItems,
            displayName: "Parent"
          });
        }

        this.formFields.find(item => {
          return item.name == "name";
        }).value = editEntity.name;

        this.formFields.find(item => {
          return item.name == "state";
        }).value = editEntity.state;

        this.formFields.find(item => {
          return item.name == "createdBy";
        }).value = editEntity.createdBy;
      }

      for (let field in type.fields) {
        let defaultValue = undefined;

        if (operation == "edit") {
          defaultValue = editEntity.properties[field];
        } else {
          if (
            type.fields[field].type == "checkbox" ||
            type.fields[field].type == "dropdowns" 
          ) {
            defaultValue = [];
          }

           if (type.fields[field].type == "yesno") {
            defaultValue = "true";
          }
          if (type.fields[field].type == "json") {
            defaultValue = "true";
          }


          if (type.fields[field].type == "entityLink") {
            defaultValue = { name: "", url: "" };
          }

          if (type.fields[field].type == "video") {
            defaultValue = { autoplay: false, url: "" };
          }

          if (type.fields[field].type == "color") {
            defaultValue = "#194d33";
          }

          if (type.fields[field].type == "dropdown") {
            defaultValue = undefined; //type.fields[field].values[0];
          }

          if (type.fields[field].type == "rank") {
            defaultValue = type.fields[field].values.maxValue / 2;
          }

          if (type.fields[field].type == "id") {
            for (let value of type.fields[field].values) {
              if (value == "{parent}") {
                let parentName = this.entityByDocId(this.$route.params.id)[0]
                  .name;
                if (defaultValue == undefined) {
                  defaultValue = parentName;
                } else {
                  defaultValue = defaultValue + "/" + parentName;
                }
              } else if (value == "{autoid}") {
                let parentEntities = this.entityByParent(this.$route.params.id);

                let nextId = (parentEntities.length + 1).toString();

                if (defaultValue == undefined) {
                  defaultValue = nextId;
                } else {
                  defaultValue = defaultValue + "/" + nextId;
                }
              }
            }
          }
        }

        this.formFields.push({
          order: type.fields[field].order,
          name: field,
          type: type.fields[field].type,
          displayName: type.fields[field].displayName,
          value: defaultValue,
          values: type.fields[field].values,
          requiredField: type.fields[field].requiredField,
          helpField: type.fields[field].help
        });
console.log(this.formFields); 
        this.formFields = _.orderBy(this.formFields, "order");
      }
    },

    dismissUploadForm() {
      // 1. Go back to search functionality.
      // 2. Destroy all post data related to form

      if (this.$route.params.id) {
        this.$router.push(
          "/" + this.$route.params.root + "/entity/" + this.$route.params.id
        );
      } else {
        this.$router.push("/site/homePage");
      }
    },
    async deleteData(dialog) {
      dialog.close();

      let editEntity = this.entityByDocId(this.$route.params.id)[0];
      this.parent = editEntity.parent;

      let fileList = [];

      for (let field of this.formFields) {
        if (field.type == "files") {
          if (field.value) {
            for (let file of field.value) {
              fileList.push(file);
            }
          }
        }
      }

      for (let item of fileList) {
        await Apicall.deleteFromFirebase(item.url);
      }

      //TODO: create a better generic way of doing extra stuff around entitiy save
      if (this.type.type == "user") {
        let result = await Apicall.deletefirebaseUser(
          editEntity.properties.uid
        );
        if (!result.updated) {
          alert(result.message.message);
          return;
        }
      }

      let results = await Apicall.deletefirebaseCollection(
        "entities",
        this.$route.params.id
      );
      //console.log(results);

      await this.$store.dispatch("populateEntities");
      //await this.$store.dispatch("populateEntityTypes");

      let nextPage = "/" + this.$route.params.root + "/";

      if (this.parent) {
        nextPage =
          "/" + this.$route.params.root + "/" + "entity/" + this.parent;
      }

      setTimeout(() => this.$router.push(nextPage), 1000);
    },
    async saveData(saveType) {
      console.log("SAVING", saveType, this.formFields);
      this.loading = true;
      await this.startProcessList(); //Process files

      let properties = {};
      let errors = [];

      let name = "";
      let state = "";
      let createdBy = "";

      for (let field of this.formFields) {
        if (
          field.requiredField &&
          (field.value == "" || field.value == undefined)
        ) {
          errors.push("<b>" + field.displayName + "</b> is required");
        }

        if (field.type == "label") {
          properties[field.name] = "";
        } else if (field.name == "name") {
          name = field.value;
        } else if (field.name == "parent") {
          parent = field.value;
          // properties[field.name]=field.value;
        } else if (field.name == "state") {
          state = field.value;
        } else if (field.name == "createdBy") {
          createdBy = field.value;
        } else {
          if (field.type == "color" && field.value.hex) {
            properties[field.name] = field.value.hex;
          } else {
            properties[field.name] = field.value;
          }
        }
      }

      if (errors.length != 0) {
        let errorMessage = "<ul>";

        for (let error of errors) {
          errorMessage += "<li>" + error + "</li>";
        }

        M.toast({
          html: errorMessage,
          classes: "rounded"
        });
        this.loading = false;
        console.log(errors);
        return;
      }

      //TODO: create a better generic way of doing extra stuff around entitiy save

      if (this.type.saveCollection) {
        // console.log("Coll", properties);

        let parentId = this.$route.params.id;
        let parent = this.entities.find(parent => {
          return parent.docId == parentId;
        });
        // console.log('UUU',this.user)
        properties["name"] = name;
        properties["dateSubmitted"] = new Date().toUTCString();
        properties["userEmail"] = this.user.email;
        properties["userName"] = this.user.name;
        properties["parentId"] = parentId;
        properties["parentName"] = parent.name;

        // debugger;

        let result = await Apicall.addfirebaseCollection(
          this.type.saveCollection,
          properties
        );

        let nextPage = "/" + this.$route.params.root + "/";
        if (this.parent != "null") {
          nextPage =
            "/" + this.$route.params.root + "/" + "entity/" + this.parent;
        }
        setTimeout(() => {
          this.loading = false;
          this.$router.push(nextPage);
        }, 1000);

        return;
      }

      if (this.type.type == "user") {
        properties.email = properties.email.toLowerCase();

        let userUpdateObject = {
          uid: properties.uid,
          displayName: name,
          email: properties.email,
          password: properties.password,
          role: properties.role
        };
        let result = await Apicall.updatefirebaseUser(userUpdateObject);
        if (!result.updated) {
          alert(result.message.message);
          return;
        } else {
          properties.uid = result.data.uid;
          properties.password = undefined;
        }
      }

      for (let item of this.deleteList) {
        await Apicall.deleteFromFirebase(item.url);
      }

      if (this.$route.params.operation == "edit") {
        let editEntity = this.entityByDocId(this.$route.params.id)[0];

        this.parent = editEntity.parent;
        let data = {
          name: name,
          state: state,
          createdBy: createdBy,
          properties: properties,
          slug: this.string_to_slug(name),
          type: this.type.type,
          parent: editEntity.parent
        };

        let results = await Apicall.updatefirebaseCollection(
          "entities",
          this.$route.params.id,
          data,
          saveType
        );
        //console.log(results);
      } else {
        let data = {
          name: name,
          state: state,
          createdBy: createdBy,
          properties: properties,
          slug: this.string_to_slug(name),
          type: this.type.type,
          parent: this.parent
        };
        let results = await Apicall.addfirebaseCollection(
          "entities",
          data,
          saveType
        );
        console.log(results);
      }
      // this.loading=false;
      let nextPage = "/" + this.$route.params.root + "/";
      if (this.parent != "null") {
        nextPage =
          "/" + this.$route.params.root + "/" + "entity/" + this.parent;
      }

      await this.$store.dispatch("populateEntities");
      await this.$store.dispatch("populateEntityTypes");

      setTimeout(() => {
        this.loading = false;
        this.$router.push(nextPage);
      }, 1000);
    }
  }
};
</script>