import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase'
//window.axios = require('axios')
import VueAnalytics from 'vue-analytics'
import axios from 'axios'


import VuejsDialog from 'vuejs-dialog';

import vueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(vueSmoothScroll)

import Api from "./mixins/Apicall";
import Appsettings from "./mixins/AppSettings";
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import VueI18n from 'vue-i18n'
import { ENGLISH_TRANSLATIONS } from '@/localization/en';
import { IRISH_TRANSLATIONS } from '@/localization/ga';

Vue.use(VueAnalytics, {
  id:'UA-180437481-1'
})
Vue.config.productionTip = false
let app = '';

Vue.use(VueI18n)

Vue.prototype.$http = axios;

Vue.mixin(Api);

Vue.mixin(Appsettings);

Vue.use(VuejsDialog);

const TRANSLATIONS = {
  en: ENGLISH_TRANSLATIONS,
  ga: IRISH_TRANSLATIONS
}
const i18n = new VueI18n({
  locale: 'en',
  messages: TRANSLATIONS,
})
// Vue.filter('formatDate', function(value) {
//   if (value) {
   
//   }
// });



// var config = {
//   apiKey: "AIzaSyCt0UF_PAGv8tZviayDwrC87MgGbXclaPs",
//   authDomain: "homes-england-pinpoint.firebaseapp.com",
//   databaseURL: "https://homes-england-pinpoint.firebaseio.com",
//   projectId: "homes-england-pinpoint",
//   storageBucket: "homes-england-pinpoint.appspot.com",
//   messagingSenderId: "42239644850",
//   appId: "1:42239644850:web:188fb7b3ac5551b930e5ab",
//   measurementId: "G-HTEM68MPJD"
// };

var config = {
  apiKey: "AIzaSyD9Fd3-W-K2rUurisz-SGqZWbMZSPZT6T0",
  authDomain: "pinpoint-gccparkingscheme.firebaseapp.com",
  databaseURL: "https://pinpoint-gccparkingscheme.firebaseio.com",
  projectId: "pinpoint-gccparkingscheme",
  storageBucket: "pinpoint-gccparkingscheme.appspot.com",
  messagingSenderId: "515894439852",
  appId: "1:515894439852:web:6ea00b179bb0bfe7d45409",
  measurementId: "G-0XWKTEPYQ2"
};

firebase.initializeApp(config);


firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
    router,
    store,
    firebase,
    i18n,
    render: h => h(App)
  }).$mount('#app')

  window.app = app;
}
});


