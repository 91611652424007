<template>
  <div class="site-view" v-if="pageData">
    <section class="site-text">
      <div class="container">
        <div class="row mb-0">
          <div class="col l12">
            <h1>{{ pageData.name }}</h1>
            <div v-html="pageData.properties.pageDescription"></div>
          </div>
        </div>
      </div>
    </section>
    <FurtherInfoCards :data="furtherInfoItems" :hoverText="'View Document'" />
    <SiteFooter />
  </div>
</template>

<script>
import firebase from "firebase";
import Settings from "@/mixins/AppSettings.js";
import { mapState, mapGetters } from "vuex";
import Logo from "@/assets/images/heathrow/logo.png";
import Branding from "@/components/Branding";
import SiteFooter from "@/components/customPages/components/footer.vue";
import FurtherInfoCards from "@/components/customPages/components/cards.vue";

export default {
  name: "furtherInfo",
  data() {
    return {};
  },
  components: {
    Branding,
    SiteFooter,
    FurtherInfoCards,
  },
  methods: {},
  computed: {
    ...mapState(["entities", "entityTypes"]),
    ...mapGetters(["user", "allEntityTypes", "allEntities"]),
    pageData() {
      if (this.$route.params.id != undefined) {
        return this.allEntities.find(
          (item) => item.docId == this.$route.params.id
        );
      } else {
        return this.allEntities.find(
          (item) => item.type == this.$route.params.templateName
        );
      }
    },
    furtherInfoItems() {
       if(this.pageData) {
        const vm = this;
        let array = vm.pageData.children.furtherinfoitem;
        let docItems = array.map(function (docID) { 
          return vm.allEntities.find((item) => item.docId == docID);
        });
       
          let orderedDocItems = _.orderBy(docItems,"properties.order")
          return orderedDocItems
      } else {
        return [];
      }
    },
  },
  watch: {
    $route(to, from) {
      this.activeInstance = to.params.root;
    },
  },
};
</script>
