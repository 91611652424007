
const AppSettings = {
  appTitle: 'GccParkingScheme',
  siteAdmin: '',
  baseUrl: 'https://europe-west2-pinpoint-gccparkingscheme.cloudfunctions.net/functions/',
  showFeedbackButton: false,
  showPostcodeFilter : false,
  showHelp: false,
  showUser: true,
  enableFilter: false,
  enableEntitySearch: false,
  enableChildrenCount: false,
  enableEntityPopup: true,
  parentChange: false,
  feedbackType: 'form',
  showMapKey: false,
  imageMapKey: 'key.png',
  mapConfig: {
      accessToken:
        "pk.eyJ1IjoiY3JlYXRpdmUtZGVzaWduIiwiYSI6ImNqYXY0d3h2eTZ5M2syd2w5eWo3Ymd5bGIifQ.cYdELtP5xXELeUZ0p_cS_g",
        templatename: "mapbox://styles/creative-design/ckhxa7voq0n9s19mlm2iu1xj7/draft",
      // templatename: "mapbox://styles/creative-design/ckda2u7zy0t6u1ipdo7vy08mx/draft", ///draft", //"mapbox://styles/mapbox/light-v10", //"mapbox://styles/mapbox/basic-v9?optimize=true",
      startLocation:[-7, 55],//[-0.457619, 51.4712667],
      startZoom: 5,
      startPitch: 0
     
    },

}; 

export default AppSettings;

