<template>
  <div id="app" class="admin-ui">
    <router-view/>
    <cookie-law :buttonText="'Accept'">
      <div slot="message">
        <!-- Here is my message for more info <router-link to="legal-notes">Click here</router-link> -->
    This website uses cookies to ensure you get the best experience on our website. 
      </div>
    </cookie-law>
  </div>
</template>

<style lang="scss">
@import "assets/styles/main";
</style>

<script>
import CookieLaw from 'vue-cookie-law';
import { mapState, mapGetters } from "vuex";
import Header from "@/components/Header";
const Stickyfill = require("stickyfilljs");

export default {
  name: "app",
  components: { CookieLaw },
  computed: {
    ...mapState(["entities"]),
    ...mapGetters([
			"user"
    ]),
    isAdmin() {
      if(this.user) {
        if(this.user.role == 'Admin') {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  data() {
    return {
      timerToken: null
    };
  },
  created() {

    console.log('adding idle events')
    // document.onmousemove = this.resetTimer;
    // document.onkeypress = this.resetTimer;
  },
  async mounted() {
    // Populate the store with entities if they are not already present
    if (this.entities.length == 0) {
      await this.$store.dispatch("populateEntities");
    }
  },
  methods: {


  },
  async created() {


    
    // console.log('LL',this.$root.$i18n.locale);
    
  },
  watch: {
    $route(to, from) { 
        if (to.params.root == 'en'){ 
          this.$root.$i18n.locale ='en'
        }else if(to.params.root == 'ga'){
          this.$root.$i18n.locale ='ga'
        }else{
          this.$root.$i18n.locale ='en'
        }
    }
  }
};
</script>


