<template>
  <main>
    <HomesEnglandHeader />
    <component v-bind:is="templateName"></component>
  </main>
</template>
<script>
import aboutPage from "../components/customPages/about";
import homePage from "../components/customPages/home2";
import faqPage from "../components/customPages/faq";
import furtherInfoPage from "../components/customPages/furtherInfoPage";
import schemesPage from "../components/customPages/schemes2";
import questionnaire from "../components/customPages/questionnaire2";
import HomesEnglandHeader from "@/components/HomesEngland/Header";
import Header from "@/components/Header";

export default {
  name: "TemplateLoader",
  components: {
    HomesEnglandHeader,
    aboutPage,
    homePage,
    faqPage,
    schemesPage,
    questionnaire,
    furtherInfoPage,
  },
  data() {
    return {};
  },
  computed: {
    templateName() {
      return this.$route.params.templateName;
    },
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
