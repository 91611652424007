import Vue from 'vue'
import Vuex from 'vuex'

import Apicall from './mixins/Apicall';

import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex)

function findParents(entities, parents, item) {
  if (item.parent != "null") {
    parents.push(item.parent)

    let nextParent = entities.find((parentItem) => { return parentItem.docId == item.parent })

    findParents(entities, parents, nextParent)

  }
  else {
    return
  }

}

function filterEnities(entities, filter) {


  let filteredEntities = []
  if (entities.length != 0) {

    let activeFilters = []
      
    
    for (let filterItem of filter) {
      activeFilters.push(filterItem.type);

      let foundItems = entities.filter((item) => {
        if (item.type == filterItem.type) {
          if (filterItem.comparison == "!=") {
            if (filterItem.field == "name") {
              return (item.name != filterItem.value)
            }
            else {
              return (item.properties[filterItem.field] != filterItem.value)
            }
          }
          else if (filterItem.comparison == "Contains") {
            
            if (item.properties[filterItem.field] == undefined && filterItem.field != "name") {
              return false
            }
            if (filterItem.field == "name") {
              return (item.name.indexOf(filterItem.value) != -1)

            }
            else {
              return (item.properties[filterItem.field].indexOf(filterItem.value) != -1)

            }
          }
          else {
            if (filterItem.field == "name") {

              return (item.name == filterItem.value)
            }
            else {
              return (item.properties[filterItem.field] == filterItem.value)
            }

          }

        }



      })

    
      for (let foundItem of foundItems) {

        let parents = []
        findParents(entities, parents, foundItem)

        filteredEntities = filteredEntities.concat(parents)
        filteredEntities.push(foundItem.docId)
      }

    }

    // let nonFilteredItems = entities.filter((item) => {
      
    //   return !activeFilters.includes(item.type)
    // })

    
    // for(let nonFilteredItem of nonFilteredItems)
    // {
    //   filteredEntities.push(nonFilteredItem.docId)
    // }

    
  }
  return filteredEntities
}

function getChildrenForEntity(currentEntity, entities) {
  let foundChildren = {};
  let children = entities.filter(item => {
    return item.parent == currentEntity.docId;
  });

  if (children.length > 0) {
    for (let child of children) {
      parentChildResolve(foundChildren, child, entities);
    }
  }

  return foundChildren;


}
function parentChildResolve(foundChildren, node, entities) {

  let children = entities.filter(item => {
    return item.parent == node.docId;
  });

  let arrayAddition = []
  arrayAddition.push(node.docId);

  if (foundChildren[node.type] == undefined) {

    foundChildren[node.type] = arrayAddition
  }
  else {
    foundChildren[node.type] = foundChildren[node.type].concat(arrayAddition)

  }

  if (children.length > 0) {
    for (let child of children) {
      parentChildResolve(foundChildren, child, entities);
    }
  } else {
    return foundChildren;
  }
}

export default new Vuex.Store({
  state: {
    feedbackData:[],
  
    userData: null,
    filters: [],
    entities: [],
    entityTypes: [],
    navigation:[],
    postcodeSearch: {},
    entitiesInView: [],
    feedback: [],
    navClasses: [],
    activeRecordSate: '',
    sateliteView: false,
    feedbackCountNew:0,
    feedbackQuestions:[],
    thankYou:false
  },
  mutations: {
    updateField,
    setFeedbackQuestions(state, feedbackQuestions) {     
      state.feedbackQuestions = feedbackQuestions
    },
    setFeedbackCount(state, feedbackCountNew) {     
      state.feedbackCountNew = feedbackCountNew
    },
    setFeedbackData(state, feedbackData) {     
      state.feedbackData = feedbackData
      //console.log('CHHH',feedbackData,state.feedbackData);
    },
    setUserData(state, userData) {
      state.userData = userData
    },
    adminSuccess(state, payload) {
      state.isAdmin = payload;
    },
    setFilter(state, payload) {
      state.filters = payload
    },
    setEntities(state, entities) {
      state.entities = entities
    },
    updateEntity(state, payload){
     // debugger;

     const index = state.entities.findIndex(item => item.docId === payload.docId)
   
     Vue.set(state.entities, index, payload)
     
      //let item = state.entities.find((item) => {return item.docId == payload.docId})    
     // debugger;
     // Object.assign(item,payload);
 
    },
    setNavigation(state, navigation) {
      state.navigation = navigation
    },
    setEntityTypes(state, entityTypes) {
      state.entityTypes = entityTypes
    },
    setPostcodeSearch(state, postcodeSearch) {
      state.postcodeSearch = postcodeSearch
    },
    setEntitiesInView(state, entitiesInView) {
      state.entitiesInView = entitiesInView
    },
    addFeedback(state, payload) {

      state.feedback.push(payload)
    },
    setThankYouState(state,payload){
      state.thankYou=payload
    },
    clearFeedback(state) {
      state.feedback = []
    },
    addNavClasses(state, payload) {
      state.navClasses.push(payload)
    },
    removeNavClasses(state, payload) {
      let index = state.navClasses.indexOf(payload);
      if (index > -1) {
        state.navClasses.splice(index, 1);
      }
    },
    setSateliteView(state, payload) {
      state.sateliteView = payload;
    }
  },
  actions: {
    setSateliteView(context, sateliteView) {
      context.commit('setSateliteView', sateliteView);
    },
    async populateEntities() {

      let entityTypes = await Apicall.getfirebaseCollectionAll(
        "entityTypes"
      );

      
      let entities = await Apicall.getfirebaseCollectionAll(
        "entities"
      );

      let typesWithMapFields = [];
      let mapfields = [];
      for (let entityType of entityTypes) {
        for (let field in entityType.fields) {
          if (
            entityType.fields[field].type == "geojsonlarge"
          ) {
            if (
              typesWithMapFields.find(item => {
                return item.docId == entityType.docId;
              }) == undefined
            ) {
              typesWithMapFields.push(entityType);
            }
            mapfields.push({ field: field, type: entityType.type });
          }
        }
      }

 
      let mapEntites = [];

      for (let currentType of typesWithMapFields) {
        let foundEntities = entities.filter((item) => {
     
          return item.type == currentType.type
        });

        mapEntites.push(...foundEntities);
      }

      for(let mapEntity of mapEntites)
      {
        
        for(let mapfield of mapfields)
        {

  
          if(mapEntity.properties[mapfield.field])
          {

            let fileUrl = JSON.parse(mapEntity.properties[mapfield.field]).url
            let file = await Apicall.getFile(fileUrl);
            mapEntity.properties[mapfield.field + '_data'] = JSON.stringify(file);
            //mapEntity.properties[mapfield.field] = '';
            //debugger

            //mapEntity.properties.type = "geojsonLargeConverted"

            //entityTypes.find((item) => {return item.type == mapfield.type}).fields[mapfield.field].type = "geojsonLargeConverted";
            entities.find((item) => {return item.docId == mapEntity.docId}).properties = mapEntity.properties;
          }
        }
      }





  
      
      entities = entities.filter((item) => item.visible == undefined || item.visible != false)
      this.commit('setEntityTypes', entityTypes)

      this.commit('setEntities', entities)

    },
    async populateEntityTypes() {
      let entityTypes = await Apicall.getfirebaseCollectionAll(
        "entityTypes"
      );

      this.commit('setEntityTypes', entityTypes)

    },
    async setNavigation() {
      let nav = await Apicall.getfirebaseCollectionAll(
        "navigation"
      );

      this.commit('setNavigation', nav[0])

    },
    setPostcodeSearch(context, search) {
      context.commit('setPostcodeSearch', search);
    },
    async newSubmitFeedback(context, FeedbackData) {
      console.log('test the feedback data',FeedbackData)
      // let feedbackRecord = {
      //   nodeFeedback:FeedbackData,
      //   dateSubmitted: new Date().toUTCString()
      // }
      let result = await Apicall.addfirebaseCollection(
        "feedback",
        FeedbackData
      );

      if (result.status == 200) {
        
       this.commit('setThankYouState', true);
      }
    },
    async submitFeedback(context, userFeedback) {
      let feedbackRecord = {
        email: userFeedback.email,
        generalComment: userFeedback.generalComment,
        name: userFeedback.name,
        nodeFeedback: this.state.feedback,
        dateSubmitted: new Date().toUTCString()
      }

      let result = await Apicall.addfirebaseCollection(
        "feedback",
        feedbackRecord
      );

      if (result.status == 200) {
        this.commit('clearFeedback');
      }
    },
    setThankYouState(context, classes) {
      context.commit('setThankYouState', classes);
    },
    addNavClasses(context, classes) {
      context.commit('addNavClasses', classes);
    },
    removeNavClasses(context, classes) {
      context.commit('removeNavClasses', classes);
    },
    setFeedbackQuestions(context, info) {
      
      context.commit('setFeedbackQuestions', info);
    },
    setFeedbackData(context, info) {
      
      context.commit('setFeedbackData', info);
    },
    setFeedbackCount(context, info) {  
      context.commit('setFeedbackCount', info);
    },

  },
  getters: {
    getField,
    user: (state) => {
      if (state.userData) {
        let foundUser = state.entities.find((item) => { return item.properties.email == state.userData.email })
        if (foundUser) {
          let userObject = { name: foundUser.name, email: foundUser.properties.email, role: foundUser.properties.role }
          return userObject;
        }

      }

    },
    getFilter: state => state.filters,
    allEntities: (state, getters) => getters.filteredEntities,
    allEntityTypes: state => state.entityTypes,
    filteredEntities: (state, getters) => {

      let filter = getters.getFilter // [{ "type": "safety_tour", "field": "status", "value": "Historic" }]

    
      let foundEntities = filterEnities(state.entities, filter)


      let typesWithMapFields = [];
      for (let entityType of state.entityTypes) {
        for (let field in entityType.fields) {
          if (
            entityType.fields[field].type == "geojsonpin" ||
            entityType.fields[field].type == "geojson" ||
            entityType.fields[field].type == "geojsonlarge"
          ) {
            if (
              typesWithMapFields.find(item => {
                return item.docId == entityType.docId;
              }) == undefined
            ) {
              typesWithMapFields.push(entityType.type);
            }
            //mapfields.push({ field: field, type: entityType.type });
          }
        }
      }

      //Find all the entities that are related to the mapping elements
     

    

      for (let entity of state.entities) {
        let children = getChildrenForEntity(entity, state.entities)
        entity.children = children

        let inFilter = false

        if (filter.length == 0) {
          inFilter = true
        }
        else {

          //Look for children 
          for (let childGroup in entity.children) {

            entity.children[childGroup] = entity.children[childGroup].filter(
              (item) => { return foundEntities.includes(item) })

            if (entity.children[childGroup].length != 0) {
              inFilter = true;
            }

          }

          if (foundEntities.includes(entity.docId)) {
            inFilter = true
          }


        }

        entity.containsMapElements =(typesWithMapFields.includes(entity.type))
      
        entity.show = inFilter;
        entity.showOnMap = (entity.showOnMap == undefined ? true : entity.showOnMap);
      }

   

      return state.entities

    },
    entityTypeByType: state => type => {

      return state.entityTypes.filter(item => item.type == type)
    },
    entityTypesByParentType: state => parentType => {

      return state.entityTypes.filter(item => item.parent.includes(parentType))
    },
    entityByType: (state, getters) => type => {

      return getters.filteredEntities.filter(item => item.type == type)
    },
    entityByParent: (state, getters) => parent => {

      return getters.filteredEntities.filter(item => item.parent == parent)
    },
    entityParentByChild: (state, getters) => child => {

      return getters.filteredEntities.filter(item => item.docId == child.parent)
    },
    entityByDocId: (state, getters) => docId => {

      return getters.filteredEntities.filter(item => item.docId == docId)
    },
    // largeGeoJsonElements: (state, getters) => {

    //   let typesWithMapFields = [];
    //   let mapfields = [];

    //   //Check which entities have mapping elements
    //   for (let entityType of state.entityTypes) {
    //     for (let field in entityType.fields) {
    //       if (
    //         entityType.fields[field].type == "geojsonlarge"
    //       ) {
    //         if (
    //           typesWithMapFields.find(item => {
    //             return item.docId == entityType.docId;
    //           }) == undefined
    //         ) {
    //           typesWithMapFields.push(entityType);
    //         }
    //         mapfields.push({ field: field, type: entityType.type });
    //       }
    //     }
    //   }

    //   //Find all the entities that are related to the mapping elements
    //   let mapEntites = [];

    //   for (let currentType of typesWithMapFields) {
    //     let foundEntities = getters.entityByType(currentType.type);

    //     mapEntites.push(...foundEntities);
    //   }

    //   let geoJsonEl = [];

    //   for (let entity of mapEntites) {
    //     let nonMapProperties = {}; //filter out nonMapProperties
    //     for (let entityProperty in entity.properties) {
    //       if (
    //         mapfields.find(item => {
    //           return item.field == entityProperty && item.type == entity.type;
    //         }) == undefined
    //       ) {
    //         nonMapProperties['pinpoint_' + entityProperty] =
    //           entity.properties[entityProperty];
    //       }
    //     }

    //     for (let entityProperty in entity.properties) {
    //       if (
    //         mapfields.find(item => {
    //           return item.field == entityProperty && item.type == entity.type;
    //         }) != undefined
    //       ) {

    //         let geoJsonElement = { docId: entity.docId, data: entity.properties[entityProperty] };


    //         geoJsonEl.push(geoJsonElement);
    //       }
    //     }
    //   }



    //   return geoJsonEl;



    // },
    geoJsonElements: (state, getters) => {

    //  debugger;

      let typesWithMapFields = [];
      let mapfields = [];

      //Check which entities have mapping elements
      for (let entityType of state.entityTypes) {
        for (let field in entityType.fields) {
          if (
            entityType.fields[field].type == "geojsonpin" ||
            entityType.fields[field].type == "geojson" ||
            entityType.fields[field].type == "geojsonlarge"
          ) {
            if (
              typesWithMapFields.find(item => {
                return item.docId == entityType.docId;
              }) == undefined
            ) {
              typesWithMapFields.push(entityType);
            }
            mapfields.push({ field: field, type: entityType.type,mapType: entityType.fields[field].type });
          }
        }
      }

      //Find all the entities that are related to the mapping elements
      let mapEntites = [];

      for (let currentType of typesWithMapFields) {
        let foundEntities = getters.entityByType(currentType.type);

        mapEntites.push(...foundEntities);
      }

      //Populate the geojson properties
      let geoJsonEl = [];

     //let mapEntites = state.entities.filter((item)=>{return item.containsMapElements == true});
     
   
      for (let entity of mapEntites) {

        let nonMapProperties = {}; //filter out nonMapProperties
        for (let entityProperty in entity.properties) {
          if (
            mapfields.find(item => {
              return item.field == entityProperty && item.type == entity.type;
            }) == undefined
          ) {
            if(entityProperty.indexOf("_data") == -1)
            {  
              nonMapProperties['pinpoint_' + entityProperty] =entity.properties[entityProperty];
            }
           
          }
        }

        for (let entityProperty in entity.properties) {

          let mapFieldType = mapfields.find(item => {
            return item.field == entityProperty && item.type == entity.type;
          }) 
         

          if (mapFieldType != undefined
          ) {
            let geoJsonElementRaw = {};
            if (typeof entity.properties[entityProperty] === "object") {
              geoJsonElementRaw = entity.properties[entityProperty];
            } else {
               if(mapFieldType.mapType == "geojsonlarge")
              {
             
                geoJsonElementRaw = JSON.parse(entity.properties[entityProperty + '_data']);
              }
              else
              {
                geoJsonElementRaw = JSON.parse(entity.properties[entityProperty]);
              }
              
            }
            let geoJsonFeatures = geoJsonElementRaw.features

            //Get features

            // console.log(entity);

            for (let geoJsonElement of geoJsonFeatures) {
              let existingProperties = geoJsonElement.properties;

              if (entity.parent == "null") {
                geoJsonElement.properties = {
                  docId: entity.docId,
                  name: entity.name,
                  type: entity.type,
                  show: entity.show,
                  showOnMap: entity.showOnMap,
                  children: entity.children,
                  ...nonMapProperties,
                  ...existingProperties
                };
              }
              else {

       
                geoJsonElement.properties = {
                  docId: entity.docId,
                  name: entity.name,
                  parent: entity.parent,
                  type: entity.type,
                  show: entity.show,
                  showOnMap: entity.showOnMap,
                  children: entity.children,
                  ...nonMapProperties,
                  ...existingProperties
                };

              }



              // debugger
              geoJsonEl.push(geoJsonElement);

            }


          }
        }
      }

  
      //console.log('returning this geojson', geoJsonEl)
      return geoJsonEl;

    },
    thankYou:state=> state.thankYou,
    feedbackData:state => state.feedbackData,
    navigation: state => state.navigation,
    postcodeSearch: state => state.postcodeSearch,
    entitiesInView: state => state.entitiesInView,
    feedback: state => state.feedback,
    feedbackCountNew: state => { state.feedbackCountNew },
    feedbackCount: state => {
      return state.feedback.length
    },
    navClasses: state => state.navClasses,
    sateliteView: state => state.sateliteView
  }
})
