<template>
  <div class="site-view">
    <section class="site-banner">
      <div class="container">
        <div class="row">
          <div class="col m12 l8">
            <h1>M5 Junction 10 Progression Scheme</h1>
            <p class="subheading">View the progression on our interactive map</p>
            <router-link to="/map">Launch interactive map</router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="site-imgl_txtr">
      <div class="container">
        <div class="row">
          <div class="col m12 l6">
            <div class="iframe-wrapper">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/ScMzIvxBSi4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div class="col m12 l6">
            <h2>The M5 junction 10 scheme is a package of transport measures which comprises the following components:</h2>
            <ol>
              <li>A new all-movements junction at M5 junction 10.</li>
              <li>A new link road from junction 10 to West Cheltenham Cyber Park.</li>
              <li>Dualling of the A4019 to the east of the link road.</li>
              <li>A38/A4019 junction improvements at Coombe Hill.</li>
            </ol>
            <p><strong>Play the video to see an overview of the scheme components</strong></p>
          </div>
        </div>
      </div>
    </section>
    <section class="site-imgr_txtl">
      <div class="container">
        <div class="row">
          <div class="col m12 l6">
            <h2>Aims and Objectives</h2>
            <p>Play the video to see an overview of the scheme components</p>
            <ul>
              <li>A new all-movements junction at M5 junction 10.</li>
              <li>A new link road from junction 10 to West Cheltenham Cyber Park.</li>
              <li>Dualling of the A4019 to the east of the link road.</li>
              <li>A38/A4019 junction improvements at Coombe Hill.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="site-timeline">
      <div class="container">
        <div class="row">
          <div class="col12">
            <h2>Timeline</h2>
            <p>Progression Scheme</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase";
import Settings from "@/mixins/AppSettings.js";
import { mapState,mapGetters } from "vuex";
import Logo from "@/assets/images/heathrow/logo.png";
import Branding from "@/components/Branding";

export default {
  name: "aboutPage",
  components: {
    Branding
  },
  data() {
    return {};
  },
  // async mounted() {
  //   if (this.entities.length == 0) {
  //     await this.$store.dispatch("populateEntities");
  //   }
  // },
  methods: {},
  computed: {
    ...mapState(["entities", "entityTypes"]),
    ...mapGetters(["user", "allEntityTypes","allEntities"]),
    pageInfo() {
      return this.entities.find(item => item.type == 'aboutPage')
    }
  },
  watch: {
  
  }
};
</script>
