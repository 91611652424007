<template>
  <section class="site-cards" v-if="data">
    <div class="container">
      <div class="row">
        <div class="site-card-col col s12 m6 l4" v-for="(items, index) in data" :key="index">
            
            <div class="site-card">
              <div class="site-card-image" :style="{ backgroundImage: `url(${items.properties.cardImage[0].url})`}">
                <p><span>{{ hoverText }}</span></p>
              </div>
              <a class="site-card-content"  target="_blank" @click="trackClick(items.properties.cardTitle,items.properties.cardFile)">
                <div class="white-text">
                  <h2>{{ items.properties.cardTitle }}</h2>
                  <p>{{ items.properties.cardDescription }}</p>
                  <p class="readmore">Read more <span><i class="material-icons">chevron_right</i></span> </p>
                </div>
              </a>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    data: Array,
    hoverText: String,
  },
  methods:{
    trackClick(buttonTitle,buttonLink){
      gtag('event', 'click', {'event_category' : 'Button', 'event_label' : buttonTitle})
      this.$ga.event('send', 'event', 'Further Info Button', 'Click', buttonTitle)
      //window.location.href = buttonLink
      window.open(buttonLink,'_blank');
    }
  }
};
</script>
