<template>
  <section class="table hide-on-med-and-down" >
    <div class="container">
      <div class="row">
        <div class="col s12 m12 l12">
            <div class="main-div">
                <div class="table-1">
                    <table class="bordered responsive-table">
                        <thead>
                        <tr>
                            <th>Indicators</th>
                            <th>Option 2 (purple)</th>
                            <th>Option 2A (orange)</th>
                            <th>Option 2B (blue)</th>
                        </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td class="title">Air quality</td>
                                <td colspan="3" class="desc"><b>Equal impact</b><br/>
                                    Monitoring work has been undertaken to understand baseline air quality levels; further
                                    modelling work to show the expected changes to these baselines will be carried out once
                                    a preferred layout has been agreed.
                                </td>
                            </tr>
                            <tr>
                                <td class="title">Noise and<br/>vibration</td>
                                <td colspan="3" class="desc"><b>Equal impact</b><br/>
                                    A road traffic noise impact assessment will be undertaken to define whether any noise
                                    mitigation measures are required in line with current legislation.
                                </td>
                            </tr>
                            <tr>
                                <td class="title">Biodiversity</td>
                                <td colspan="3" class="desc"><b>Equal impact</b><br/>
                                    Once the road is in use, none of these options are anticipated to adversely affect any
                                    designated sites. Designated sites are those protected at an international, European or
                                    national level for wildlife.
                                </td>
                            </tr>
                            <tr>
                                <td class="title">Drainage<br/>and water<br/>environment</td>
                                <td colspan="3" class="desc"><b>Equal impact</b><br/>
                                Mitigation work to maintain flood flows and compensate for construction in the floodplain
                                will establish no adverse impact on flood risk, across all options.
                                </td>
                            </tr>
                            <tr>
                                <td class="title">Landscape and<br/>visual impact</td>
                                <td colspan="3" class="desc"><b>Equal impact</b><br/>
                                The overall impact of each option on the landscape is negligible. Mitigation measures established
                                during the construction stage will mean that any adverse visual effect of the scheme would
                                lessen as planting matures over time.
                                </td>
                            </tr>
                            <tr>
                                <td class="title">Geology<br/>and soils</td>
                                <td colspan="3" class="desc"><b>Equal impact</b><br/>
                                    Impacts on land contamination, geology and geomorphology have been assessed as neutral to
                                    minor beneficial following mitigation, therefore not resulting in any significant residual effects for
                                    all scheme options in the operational stage.
                                </td>
                            </tr>
                            <tr>
                                <td class="title">Cultural<br/>heritage</td>
                                <td colspan="3" class="desc"><b>Equal impact</b><br/>
                                    There are not expected to be any significant adverse effects on cultural heritage across
                                    any option.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-2">
                    <table class="bordered responsive-table">
                        <thead>
                        <tr>
                            <th>Indicators</th>
                            <th style="width: 27%;">Option 2 (purple)</th>
                            <th style="width: 27%;">Option 2A (orange)</th>
                            <th style="width: 27%;">Option 2B (blue)</th>
                        </tr>
                        </thead>

                        <tbody>
                             <tr>
                                <td class="title">Connectivity</td>
                                <td colspan="3" class="desc"><b>Equal impact</b><br/>
                                    All options are anticipated to provide better connectivity for existing and new users of all
                                    transport modes in the area.
                                </td>
                            </tr>
                             <tr>
                                <td class="title">Population and<br/>human health</td>
                                <td colspan="3" class="desc"><b>Equal impact</b><br/>
                                    Across all three options it is considered there will be equal potential benefits to the population
                                    including enhanced accessibility to new housing, employment and leisure opportunities.
                                </td>
                            </tr>
                            <tr>
                                <td class="title">Impact on land<br/>and /or property</td>
                                <td class="desc"><b>Higher impact</b><br/>
                                    There will be a requirement to acquire agricultural land. This option will affect a
                                    higher number of residential properties as compared to Option 2A.
                                </td>
                                <td class="desc"><b>Lower impact</b><br/>
                                    There will be a requirement to acquire agricultural land and some residential properties.                                
                                </td>
                                <td class="desc"><b>Higher impact</b><br/>
                                    There will be a requirement to acquire agricultural land. This option will affect a higher number of residential properties as compared to Option 2A.
                                </td>
                            </tr>
                             <tr>
                                <td class="title">Safety</td>
                                <td colspan="3" class="desc"><b>Equal impact</b><br/>
                                    Improving the junction will increase the junction capacity and improve traffic flow, for all vehicles
                                    including emergency service vehicles. Queuing on the hard shoulder will be reduced, as will the
                                    likelihood of rear shunt collisions.
                                </td>
                            </tr>
                            <tr>
                                <td class="title">Construction<br/>impact</td>
                                <td class="desc"><b>Higher impact</b><br/>
                                        The construction period of two years will involve temporary road closures.
                                        This option will have a more significant impact on the M5 due to the requirement for a bridge demolition.                                
                                </td>
                                <td class="desc"><b>Lower impact</b><br/>
                                    The construction period of two years will involve temporary road closures.
                                </td>
                                <td class="desc"><b>Lower impact</b><br/>
                                        The construction period of two years will involve temporary road closures.                                
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div class="table-3">
                    <table class="bordered responsive-table">
                        <thead>
                            <tr>
                                <th>Cost</th>
                                <th>£255 million</th>
                                <th>£230 million</th>
                                <th>£246 million</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td class="title">Benefit Cost<br/>Ratio</td>
                                <td class="desc">2.28</td>
                                <td class="desc">2.52</td>
                                <td class="desc">2.36</td>
                            </tr>
                            <tr>
                                <td class="title">Value for money</td>
                                <td class="desc">High</td>
                                <td class="desc">High</td>
                                <td class="desc">High</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
                
    
        </div>
      </div>
    </div>
  </section>
</template> 
<script>
export default {
  
};
</script>
