import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase'
import EntityOverview from "@/components/EntityOverview";
import MapPanel from './components/Map.vue'
import store from './store'
import EntityEdit from "@/components/EntityEdit"

import TemplateLoader from "@/views/TemplateLoader"

import ClusterView from './views/Clusterview.vue'


window.axios = require('axios')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: '/login'
    },
    {
      path: '/export/:collectionName',
      name: 'export',
      component: () => import("@/views/CollectionExport.vue"),
      meta:
      {
        requiresAuth: true,
        anonymous: false
      }
    },
    {
      path: '/help',
      name: 'help',
      component: () => import("@/views/Help.vue"),
      meta:
      {
        requiresAuth: true,
        anonymous: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
      meta:
      {
        requiresAuth: false,
        anonymous: false
      }
    },
    {
      path: '/site/:templateName/:id?',
      name: 'template',
      component: TemplateLoader,
      meta:
      {
        requiresAuth: true,
        anonymous:true
      }
    },
    {
      path: '/',
      name: 'root',
      component: ClusterView,
      children: [
        {
          path: '',
          components: {
            a: EntityOverview,
            b: MapPanel
          }
        },
        {
          path: ':root',
          components:
          {
            a: EntityOverview,
            b: MapPanel
          }
        },
        {
          path: ':root/entity/:id',
          components:
          {
            a: EntityOverview,
            b: MapPanel
          }
        },
        {
          path: ':root/entity/:id/add',
          components:
          {
            a: EntityEdit,
            b: MapPanel
          }
        },
         {
          path: ':root/entity/:id/:operation/:type',
          name: 'Pin add/edit',
          components:
          {
            a: EntityEdit,
            b: MapPanel
          }
        }
      ],
      meta:
      {
        requiresAuth: true,
        anonymous:true
      }
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  const currentUser = firebase.auth().currentUser;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const anonymous = to.matched.some(record => record.meta.anonymous);

  //if requires auth and user not logged in to to login page
  if (requiresAuth && !currentUser) {

    
    if(anonymous)
    {
   
      await firebase.auth().signInAnonymously();
      next();

    }
    else
    {
      next('login');
    }
   
  }

  //if does not require login and other page redirect to instance
  else if (!requiresAuth && currentUser && (to.path != "/login"))  {
    
    next("site/homePage");
  }
  else {

    if(currentUser)
    {
      store.commit("setUserData", currentUser);
    
    }

    next()

  }

    // //If there is no user details then redirect to next page
    // if (!currentUser) {
    //   next();
    // }

    // //Otherwise if the user data is in store then move next
    // if (store.state.userData) {
    //   next();
    // }

    // //If not query the firebase api to get the user details
    // else {

    //   if(firebase.auth().currentUser)
    //   {

    //     if(!anonymous)
    //     {
    //       let token = await firebase.auth().currentUser.getIdToken()

    //       const header = { Authorization: `Bearer ${token}` };
    
    //       if(token)
    //       {
    //         try
    //         {
    //           let res = await axios.get("https://europe-west2-calderdale-e3620.cloudfunctions.net/calderdale/signIn", { headers: header })
    
    //           store.commit("setUserData", res.data);
    //           if (res.data.role == "dataprovider") {
    //             store.commit("adminSuccess", true);
    //           }
    //           else {
    //             store.commit("adminSuccess", false);
    //           }
  
    //           next();
    //         }
    //         catch(err)
    //         {
    //           console.log(err);
    //         }
          
    //       }

    //     }
    //     else
    //     {
    //       next();
    //     }
  
    //   }
     
    





   // }

 // }

}
);

export default router;
