<template>
  <div class="site-view schemes" v-if="pageData">
    <section class="white-bg ptb-120">
        <div class="container">
            <div class="row mb-0">
                <div class="col l4">
                    <!-- <h1>About the consultation</h1> -->
                    <h1 v-html="pageData.properties.pageTitle"></h1>
                </div>
                <div class="col l8 grey-text" v-html="pageData.properties.pageDescription"></div> 
            </div>
        </div>
    </section>
    <section class="blue-bg pt-40">
        <div class="container">
            <div class="row mb-0">
                <div class="col l5">
                    <h2>{{pageData.properties.videoTitle}}</h2>
                    <div v-html="pageData.properties.videoText"></div>
                </div>
                <div class="col m12 l7">
                    <div class="responsive-video">
                        <div class="video-wrapper top-neg-89" >
                            <iframe :src="pageData.properties.video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blue-bg pb-120 pt-89">
        <div class="container">
            <div class="row mb-0 align-col-center">
                <div class="col l6 ml-0">
                   <div class="responsive-video">
                        <div class="video-wrapper " >
                            <iframe :src="pageData.properties.video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                        </div>
                        <!-- OEjMNS5AtpXjQKbAYPpk -->
                    </div>
                    <!-- <div class="responsive-img">
                        VGd8Efff1SW8IVf6uf72
                       <img :src="pageData.properties.image"/>
                   </div> -->
                </div>
                <div class="col l5">
                    <h2>{{pageData.properties.imageTitle}}</h2>
                    <full-page ref="fullpage" :options="fullpage_options_scheme" id="fullpage">
                        <div class="section fp-auto-height" id="section0">

                            <div class="slide" v-for="(item, index) in sliders" v-if="sliders.length!=0">
                                <div class="slider--content">
                                    <p>{{item.properties.SliderContent}}</p>
                                </div>
                            </div>
                        </div>
                    </full-page>
                    <!-- <p v-html="pageData.properties.imageText"></p> -->
                    <!-- <p class="mb-20">{{pageData.properties.imageText}}</p> -->
                </div>
            </div>
        </div>
    </section>
    <section class="blue-bg pb-40">
        <div class="container">
            <div class="row mb-0">
                <h2 class="mb-60">{{pageData.properties.sectionTitle}}</h2>


                <div class="col custom-col grid-example" v-for="(item, index) in schemeItems" v-if="schemeItems.length!=0">
                    <div class="responsive-img round-corner">
                        <img :src="item.properties.files[0].url">
                    </div>
                    <p>{{item.properties.Content}}</p>
                </div>
            </div>
        </div>
    </section>
    <section class="white-bg ptb-120">
        <div class="container">
            <div class="row mb-0">
                <div class="col l6">
                    <!-- <h1>About the consultation</h1> -->
                    <h2 class="col l6" v-html="pageData.properties.videoTitle2"></h2> 
                </div>
                <div class="col l6 grey-text" v-html="pageData.properties.videoText2"></div> 
            </div>
        </div>
    </section>
    <section class="blue-bg ptb-40">
        <div class="container text-align-center ">
            <div v-html="pageData.properties.feedbackText">
            </div>
            <a :href="pageData.properties.buttonLink" class="btn">{{pageData.properties.buttonText}}</a>
        </div>
    </section>

    <SiteFooter />
  
  </div>
</template>

<script>
import firebase from "firebase";
import Settings from "@/mixins/AppSettings.js";
import { mapState,mapGetters } from "vuex";
import Logo from "@/assets/images/heathrow/logo.png";
import Branding from "@/components/Branding";
import SiteAccordion from "@/components/customPages/components/accordionStatic.vue";
import SiteFooter from "@/components/customPages/components/footer.vue";
import _ from "lodash";

// importing FullPage JS
import VueFullPage from 'vue-fullpage.js'
import 'fullpage.js/dist/fullpage.css' // piping the css into the main css
import Vue from 'vue'
Vue.use(VueFullPage)

export default {
  name: "schemesPage",
  components: {
    Branding, SiteAccordion, SiteFooter
  },
  methods: {},
  computed: {
    ...mapState(["entities", "entityTypes"]),
    ...mapGetters(["user", "allEntityTypes", "allEntities"]),
    pageData() {
      if(this.$route.params.id != undefined) {
        return this.allEntities.find(item => item.docId == this.$route.params.id)
      } else {
        return this.allEntities.find(item => item.type == this.$route.params.templateName)
      }
    },
    schemeItems() {
      if(this.pageData) {
        const vm = this;
        let array = vm.pageData.children.schemeitem;
        let mapped = array.map(function(docID) {
          return vm.allEntities.find(item => item.docId == docID);
        })
        return _.orderBy(mapped, ['properties.order'], ['asc']);
      } else {
        return [];
      }
    },
    sliders() {
      if(this.pageData) {
        const vm = this;
        let array = vm.pageData.children.slidertext;
        let mapped = array.map(function(docID) {
          return vm.allEntities.find(item => item.docId == docID);
        })
        return _.orderBy(mapped, ['properties.order'], ['asc']);
      } else {
        return [];
      }
    },
    fullpage_options_scheme() {
        return{
                // anchors: ['firstPage', 'secondPage', '3rdPage'],
                autoScrolling: false,
                css3: true,
                fitToSection: false,
                controlArrows: false,
                slidesNavigation: true,
                scrollHorizontally: true,
                // paddingTop: '20px',
            }
        },

  },
  watch: {
    $route(to, from) {
      this.activeInstance = to.params.root;
    }
  }
};
</script>
