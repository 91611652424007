<template>
    <section class="site-accordion">
        <div class="container">
            <div class="row">
                <div class="col s12 m12 l12">
                    <ul class="collapsible site-collapsible">
                        <li v-for="(item, index) in data" :key="index">
                            <div class="collapsible-header">
                                {{ getHeaderField(item) }}
                              <span><i class="material-icons">expand_more</i></span>
                            </div>
                            <div class="collapsible-body">
                                <span v-html="getContentField(item)"></span>
                            </div>
                            
                        </li>
                         
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "site-accordion",
    props: {
        data: Array,
        headerField: String,
        contentField: String,
    },
    mounted() {
        this.$nextTick(() => {
            let elems = document.querySelectorAll('.collapsible');
            let instances = M.Collapsible.init(elems, {});
        });
    },
    methods: {
        getHeaderField(item) {
            return item.properties[this.headerField];
        },
        getContentField(item) {
            return item.properties[this.contentField];
        }
    }
};
</script>
