<template>
  <div class="mui-main">
    <div class="screen">
      <div class="content-wrapper">
        <div class="content">
          <slot name="map"></slot>
        </div>
      </div>
      <div class="board">
        <a href="#" class="drag-wrapper">
          <div class="drag"></div>
        </a>
        <div class="mobilemenu">
          <div class="overflow">
            <slot name="info"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
require('@/assets/plugins/hammer.js');

export default {
  name: 'mobileLayout',
  props: {
    midValue: {
      type: Number,
      default: 350
    },
  },
  data() {
    return {
      board: null,
      boardMinIn: false,
      boardTopInitial: 0,
      boardTopCollaped: 109,
      threshold: 50
    }
  },
  computed: {
    boardMid() {
      return this.midValue;
    }
  },
  watch: {
    $route(to, from) { 
        if (to.params.root == 'en'){
          this.$root.$i18n.locale ='en'
        }else{
          this.$root.$i18n.locale ='ga'
        }
    }
  },
  mounted() {

    // Scope
    const vm = this;

    vm.$nextTick(function(){

      // Config SliderManager
      let sliderManager = new Hammer.Manager(document.querySelector('.drag-wrapper'));

      // Global Vars
      vm.board = document.querySelector('.board');
      let board = vm.board;
      vm.boardTopInitial = board.style.top = board.offsetTop;

      // Config Events
      sliderManager.add(new Hammer.Pan({
        threshold: 0,
        pointers: 0
      }));
      sliderManager.add( new Hammer.Tap({ 
        event: 'doubleTap', 
        taps: 2 
      }));

      // Functionality: Pan
      sliderManager.on('pan', function(e) {
        
        e.preventDefault();
        board.classList.remove("transitable");
        board.style.removeProperty('height');
        board.classList.add("full-height");

        // Move content to top on swipe up.
        let scrollBody = document.getElementById("overview-component")
        if(scrollBody){ scrollBody.scrollTop = 0; }

        if (!board.classList.contains('pinned-top') && e.deltaY < 0) {
          
          console.log('swipe up')
          
          if(!vm.boardMidIn) {
            board.style.top = vm.boardTopInitial + e.deltaY + "px";
          } 
          else {
            board.style.top = vm.boardMid + e.deltaY + "px";
          }

          if (e.isFinal) {
            board.classList.add("transitable");
            if (Math.abs(e.deltaY) > vm.threshold) {
              board.style.top = vm.boardTopCollaped + "px";
              board.classList.add("pinned-top");
              board.classList.add("full-height");
              vm.boardMidIn = false;
            } 
            else {
              board.setAttribute('style', '');
              board.classList.remove("full-height");
            }
          }

        } 
        else if ((board.classList.contains('pinned-top') || vm.boardMidIn) && e.deltaY > 0) {
          
          console.log('swipe down')
          
          if(!vm.boardMidIn) {
            board.style.top = vm.boardTopCollaped + e.deltaY + "px";
          } 
          else {
            board.style.top = vm.boardMid + e.deltaY + "px";
          }
        
          if (e.isFinal) {
            board.classList.add("transitable");
            if (Math.abs(e.deltaY) > vm.threshold) {
              if(board.classList.contains('pinned-top')) {
                board.setAttribute('style', '');
                board.classList.remove("pinned-top");
                
                // Remove Full Height once animation completes
                // board.classList.remove("full-height");
                let transitionEnd = vm.whichTransitionEvent();
                window.addEventListener(transitionEnd, function removeHeight () {
                  console.log('end 1');
                  board.classList.remove("full-height");
                  window.removeEventListener(transitionEnd, removeHeight, false);
                }, false);
              } 
              else {
                vm.boardMidIn = false;
                board.setAttribute('style', '');
                // Remove Full Height once animation completes
                // board.classList.remove("full-height");
                let transitionEnd = vm.whichTransitionEvent();
                window.addEventListener(transitionEnd, function removeHeight () {
                  console.log('end 2');
                  board.classList.remove("full-height");
                  window.removeEventListener(transitionEnd, removeHeight, false);
                }, false);

              }
            } 
            else {
              board.style.top = vm.boardTopCollaped + "px";
              board.classList.add("top");
            }
          }
        } 
        else {
          if(!board.classList.contains('pinned-top')) {
            board.classList.remove("full-height");
          }
        }

      });

      // Functionality: Tap
      sliderManager.on('doubleTap', function(e) {
        e.preventDefault();
        console.log('fired event')
        // board.classList.remove("mid-height");
        board.style.removeProperty('height');
        board.classList.add("full-height");
        if (!board.classList.contains('pinned-top')) {
          if (e.isFinal) {
            board.classList.add("transitable");
            board.style.top = vm.boardTopCollaped + "px";
            board.classList.add("pinned-top");
            vm.boardMidIn = false;
          }
        }
      });

    });

  },
  watch: {
    // Mobile View Code
    $route(to, from) {
      //Scroll to top of overviewbody between routes
      let scrollBody = document.getElementById("overview-component")
      if(scrollBody){ scrollBody.scrollTop = 0; }
    }
  },
  methods: {
    // Handle MidPoints
    mapPointer(){
      console.log('mapPointer');

      const vm = this;
      vm.$nextTick(function(){
        vm.boardMidIn = true;
        vm.board.style.top = vm.boardMid + "px";
        // vm.board.style.height = 'calc(100% - ' + this.boardMid + 'px)';
        vm.board.classList.remove('pinned-top')
        vm.board.classList.remove('full-height')
      })

      /**
       * NOTES: 
       * The below code is causing issues with when 
       * midpoint scrolling is disabled. The code has been commeneted out for now,
       * untill if/when midpoint scrolling is required again.
       */
      // // Add scroller once transition completed and remove transition event from window.
      // let transitionEnd = vm.whichTransitionEvent();
      // window.addEventListener(transitionEnd, function removeHeight () {
      //   // vm.board.style.height = 'calc(100% - ' + vm.boardMid + 'px)';
      //   vm.board.classList.remove('full-height')
      //   window.removeEventListener(transitionEnd, removeHeight, false);
      // }, false);

    },
    whichTransitionEvent() {
      let t;
      let el = document.createElement('fakeelement');
      let transitions = {
        'transition':'transitionend',
        'OTransition':'oTransitionEnd',
        'MozTransition':'transitionend',
        'WebkitTransition':'webkitTransitionEnd'
      }

      for(t in transitions){
          if( el.style[t] !== undefined ){
              return transitions[t];
          }
      }
    }
  }
}
</script>
