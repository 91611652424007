<template>
    <!-- <section class="site-accordion">
        <div class="container">
            <div class="row">
                <div class="col s12 m12 l12">
                    <ul class="collapsible site-collapsible">
                        <li v-for="(item, index) in data" :key="index">
                            <div class="collapsible-header"><div class="ch-wrap">{{ getHeaderField(item) }}</div></div>
                            <div class="collapsible-body">
                                <span v-html="getContentField(item)"></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section> -->
    <section class="site-accordion">
        <div class="container">
            <div class="row">
                <div class="col s12 m12 l12">
                    <ul class="collapsible site-collapsible">
                        <li class="">
                            <div class="collapsible-header" tabindex="0">
                                Scheme element 1: Improvements to Junction 10 on the M5 and a new road linking Junction 10 to west Cheltenham
                                      <span><i class="material-icons">expand_more</i></span>
                            </div>
                            <div class="collapsible-body" style="">
                                <span>

                                 <!--START 1  -->
                                    <p>In its existing layout, M5 Junction 10 currently only provides slip roads from the north and to the north. This means that traffic from Cheltenham must access the southbound M5 via Junction 11. This has put increasing pressure on already congested roads.</p>
                                    <p>The Options selection process is detailed in the flow diagram below:</p>
                                
                                    <!-- <p> BCR is used by the Department for Transport (DfT) to determine whether the economic benefits of a project will be greater than the cost of implementing the project. According to the Department for Transport (DfT), achieving value for money can be described as using public resources in a way that creates and maximises public value, and is a key consideration of the decision making process.</p> -->
                                    <p>  </p>
                                     <!-- <p><b>FlOw Chart</b></p> -->

                                    <img src="@/assets/images/maps/Scheme_Element_Chart.jpg" alt="" style="display:block; width:70%; margin: 0 auto;"/>

                                    <p>A description of each option, their Benefit Cost Ration (BCR), Value for Money, and costs is summarised in the sections below. Definitions for these can be found in our <a href="https://firebasestorage.googleapis.com/v0/b/pinpoint-m5j10.appspot.com/o/Futher%20info%2FGlossary%20of%20technical%20terms.pdf?alt=media&token=85b1234b-a9ff-4c19-aa7d-4f2cb7496346" target="_blank"><b>Glossary </b></a></p>

                                    <p><b>Proposed Options: </b></p>
                                    <ul class="collapsible site-collapsible">
                                    <li>
                                        <div class="collapsible-header bg-purple">
                                            <div class="ch-wrap">Option 2 (purple): Upgrade existing junction with grade separated roundabout centred on the existing junction</div>
                                        </div>
                                        <div class="collapsible-body">
                                            <div class="body-wrapper">
                                                <div class="col-2">
                                                <div>
                                                    <p>The existing junction would be changed by constructing a new grade separated roundabout and four new slip roads to provide access in all directions. With this option, the existing bridge over the motorway would be demolished and two new bridges constructed to carry the new (grade separated) roundabout. The location of this option would be next to the existing Junction 10.
                                                        <a href="https://m5-junction-10-have-your-say.gloucestershire.gov.uk/map/entity/SMbmMl8qtHhIDfSQ5wpZ">The option can be found here on the interactive map</a>
                                                    </p>
                                                    <div class="feature">
                                                        <p><strong>Cost:</strong> £255 million</p>
                                                        <p><strong>Benefit Cost Ratio (BCR):</strong> 2.28</p>
                                                        <p><strong>Value for Money:</strong> High</p>
                                                    </div>
                                                </div>
                                                <div>                       
                                                    <img src="@/assets/images/maps/M5J10_Map_Option2-01.jpg" alt=""/>                   
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="collapsible-header bg-orange">
                                            <div class="ch-wrap">Option 2A (orange): Upgrade existing junction with grade separated roundabout offset to the north</div>
                                        </div>
                                        <div class="collapsible-body">
                                            <div class="body-wrapper">
                                                <div class="col-2">
                                                <div>
                                                    <p>The existing junction would be upgraded to provide a new grade separated roundabout using the existing bridge over the motorway and constructing a new bridge to the north. Four new slip roads would connect the junction to the motorway, providing access in all directions. The location of this option would be adjacent to the existing Junction 10.<a href="https://m5-junction-10-have-your-say.gloucestershire.gov.uk/map/entity/1mgWWrhPr4fy3BIYSFNP">The option can be found here on the interactive map</a></p>
                                                    <div class="feature">
                                                        <p><strong>Cost:</strong> £230 million</p>
                                                        <p><strong>Benefit Cost Ratio (BCR):</strong> 2.52</p>
                                                        <p><strong>Value for Money:</strong> High</p>
                                                    </div>
                                                </div>
                                                <div>                       <img src="@/assets/images/maps/M5J10_Map_Option2A-01.jpg" alt=""/>                   </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="collapsible-header bg-blue">
                                            <div class="ch-wrap">Option 2B (blue): Upgrade existing junction with grade separated roundabout offset to the south</div>
                                        </div>
                                        <div class="collapsible-body">
                                            <div class="body-wrapper">
                                                <div class="col-2">
                                                <div>
                                                    <p>The existing junction would be upgraded to provide a new grade separated roundabout using the existing bridge over the motorway and constructing a new bridge to the south. Four new slip roads would connect the junction to the motorway, providing access in all directions. The location of this option would be adjacent to the existing Junction 10.<a href="https://m5-junction-10-have-your-say.gloucestershire.gov.uk/map/entity/2xAsB9cIWcGxyMT6zKjW">The option can be found here on the interactive map</a></p>
                                                    <div class="feature">
                                                        <p><strong>Cost:</strong> £246 million</p>
                                                        <p><strong>Benefit Cost Ratio (BCR):</strong> 2.36</p>
                                                        <p><strong>Value for Money:</strong> High</p>
                                                    </div>
                                                </div>
                                                <div>                       <img src="@/assets/images/maps/M5J10_Map_Option2B-01.jpg" alt=""/>                   </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    </ul>
                                    <p><b>Rejected Options:</b></p>
                                    <p>Both options have been rejected as they do not provide high value for money and would have a significant impact on high quality agricultural land.</p>
                                    <p>As Option 1A (yellow) and Option 5 (grey) were part of our recent detailed assessment, we have included a short summary of these options.</p>
                                    <ul class="collapsible site-collapsible">
                                    <li>
                                        <div class="collapsible-header bg-yellow">
                                            <div class="ch-wrap">Rejected Option - Option 1A (yellow): New junction north of existing Junction 10</div>
                                        </div>
                                        <div class="collapsible-body">
                                            <div class="body-wrapper">
                                                <div class="col-2">
                                                <div>
                                                    <p>A new motorway junction would be constructed approximately 1250m north of the existing M5 Junction 10. This junction would provide access to the M5 in all directions, therefore the existing northbound and southbound slip roads at Junction 10 would be removed, with the existing A4019 bridge maintained for through traffic only.</p>
                                                    <div class="feature">
                                                        <p><strong>Cost:</strong> £306 million</p>
                                                        <p><strong>Benefit Cost Ratio (BCR):</strong> 1.72</p>
                                                        <p><strong>Value for Money:</strong> Medium</p>
                                                    </div>
                                                </div>
                                                <div>                       <img src="@/assets/images/maps/M5J10 option 1A.jpg" alt=""/>                   </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="collapsible-header bg-grey">
                                            <div class="ch-wrap">Rejected Option - Option 5 (grey): New junction north of existing Junction 10 (in alternative position to Option 1A)</div>
                                        </div>
                                        <div class="collapsible-body">
                                            <div class="col-2">
                                                <div>
                                                <p>A new motorway junction would be constructed approximately 1000m north of the existing M5 Junction 10. This junction would provide access to the M5 in all directions, therefore, the existing north bound and southbound slip roads at Junction 10 would be removed, with the existing A4019 bridge maintained for through traffic only</p>
                                                <div class="feature">
                                                    <p><strong>Cost:</strong> £294 million</p>
                                                    <p><strong>Benefit Cost Ratio (BCR):</strong> 1.83</p>
                                                    <p><strong>Value for Money:</strong> Medium</p>
                                                </div>
                                                </div>
                                                <div>                   
                                                <img src="@/assets/images/maps/M5J10 option 5-01.jpg" alt=""/>               
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    </ul>
                                    <p>Key information on the potential impacts of each option can be found in the tables below so you can compare like for like with the three options we are presenting. In each case, we have outlined which option is anticipated to have a <b>lower</b> or <b>higher</b> impact relative to each other. Where the difference in impact is negligible, this is stated as <b>equal impact</b> across options. Additional information about our assessments can be found in <a href="https://m5-junction-10-have-your-say.gloucestershire.gov.uk/site/furtherInfoPage"><b>technical documents and videos</b></a> that support this consultation.  </p>
                                    <p>As the scheme progresses all areas of work will be revisited and reassessed to ensure impacts are fully understood and minimised.</p>
                                    <Tables></Tables>

                                    <img class="showOnSmall hide-on-med-and-up show-on-medium-and-down" src="@/assets/images/maps/M5_Maps-01.svg" alt="" style=""/>
                                    
                                    <img class="showOnSmall hide-on-med-and-up show-on-medium-and-down" src="@/assets/images/maps/M5_Maps-02.svg" alt="" style="display:block; width:100%; margin: 0 auto;"/>

                                    <!-- END 1-->


                                </span>
                            </div>
                        </li>
                        <li>
                            <div class="collapsible-header" tabindex="0">
                              Scheme element 2: A38/A4019 Junction Improvements at Coombe Hill    <span><i class="material-icons">expand_more</i></span>
                            </div>
                            <div class="collapsible-body">
                                <span>
                                    <!-- Start 2 -->
                                    <p>To improve the flow of traffic from the A38 to the A4019 and M5 Junction 10, three initial options were identified including two roundabout and one traffic signal improvement. When assessed, the traffic signal improvement performed better and was taken forward for further work. Three further options were developed and assessed including:</p>
                                    <li><a href="https://m5-junction-10-have-your-say.gloucestershire.gov.uk/map/entity/OaDtJismKOm8KOrhLg48 ">Option 1:</a> a traffic-light controlled junction, but with some lanes leading up to the junction on A4019 and A38 (south) removed and more pedestrian crossings installed.</li>
                                    <li><a href="https://m5-junction-10-have-your-say.gloucestershire.gov.uk/map/entity/PC2liBgEk1RX1HFO9D9F ">Option 2:</a> as per Option 1, but left turns from the A38 (north) to the A4019 would be ‘give-way’. Both A38 arms would have pedestrian crossings installed.</li>
                                    <li><a href="https://m5-junction-10-have-your-say.gloucestershire.gov.uk/map/entity/iOjUxSYKM6VyFoet4pMW">Option 3: </a>as per Option 2, but with traffic-light controlled left turns from the A38 (north) onto the A4019.</li>
                                    <p>Option 3 alongside Option 2 offers the greatest operational benefits with Option 3 having the least land and environmental impact as well as having the lowest construction cost. Option 3 has been taken forward as the proposed option.</p>
                                     <p><b>Preferred option</b> </p>
                                    <p>Option 3: The existing left turn lane from the A38 onto the A4019 is replaced with a longer traffic-light controlled left turn lane. Pedestrian crossing facilities are improved, and on-carriageway cycle lead-in lanes may be provided. Road lighting provision may be increased to improve safety.</p>
                                    
                                    <!-- End 2 -->
                                    <p>Additional information about our assessments can be found in <a href="https://m5-junction-10-have-your-say.gloucestershire.gov.uk/site/furtherInfoPage"><b>technical documents and videos</b></a> that support this consultation. </p>
                                    <img src="@/assets/images/Coombe_Hill_V3-01.png" alt="" style="display:block; width:70%; margin: 0 auto;"/>
                                </span>
                            </div>
                        </li>
                        <li>
                            <div class="collapsible-header" tabindex="0">
                               Scheme element 3: A4019 widening, east of Junction 10<span><i class="material-icons">expand_more</i></span>
                            </div>
                            <div class="collapsible-body">
                                <span> 
                                    <!-- Start 3 -->
                                    <p> In order to meet the scheme objectives, whilst being mindful of local constraints and environmental and cost assessments, one option for the A4019 has been selected to be taken forward. This is a standard dual carriageway, with one segregated footway and one segregated cycleway. In order to meet the scheme objectives, whilst being mindful of local constraints and environmental and cost assessments, one option for the A4019 has been selected to be taken forward. This is a standard dual carriageway, with one segregated footway and one segregated cycleway.</p>
                                    <p>There may be minor changes in noise and air quality levels as a result of the changes in the road layout (dependent on traffic volume and speeds). It would also be necessary to acquire land, predominantly to the north of the A4019, leading to some ecological impacts and potential loss of agricultural land.</p>
                                      <p><b>Preferred option</b> </p>
                                    <p>Option 1 (standard dual carriageway): The existing single carriageway would be converted to a dual carriageway by widening the road, mostly on the northern side. We are also looking at providing a segregated footway and cycleway to the north of the A4019 with appropriate crossing facilities to connect to properties to the south of the A4019.</p>
                                     <p> The benefits of this option include: </p>
                                         <ul class="listItems">
                                        <li>Online widening of the A4019 would ensure it has the capacity to cater for increases in traffic and help reduce congestion and the associated noise and air quality impacts. </li>
                                        <li> Providing a central reserve with safety barrier would ensure safety is not compromised due to the increased opposing traffic flows.</li>
                                        <li> Providing one segregated footway and   one segregated cycleway provides a safer environment for non-motorised users and promotes alternative modes of transport between Cheltenham and the new developments.</li> </ul>
                                    <p> There may be minor changes in noise and air quality levels as a result of the changes in the road layout (dependent on traffic volume and speeds). It would also be necessary to acquire land, predominantly to the north of the A4019, leading to some ecological impacts and potential loss of agricultural land. We would like to gather your views on this option to help inform the next stages of design.</p>  
                                   
                                    <p>Additional information about our assessments can be found in <a href="https://m5-junction-10-have-your-say.gloucestershire.gov.uk/site/furtherInfoPage"><b>technical documents and videos</b></a> that support this consultation. </p>
                                     <img src="@/assets/images/A4019-01.png" alt="" style="display:block; width:70%; margin: 0 auto;"/>
                                    <!-- END 3 -->
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col s12 m12 l12">
                    <p>
                        An upgrade to Arle Court Park and Ride was also included as part of the package of improvements funded by Homes England. Gloucestershire County Council has decided to take this forward through a different planning route meaning that this scheme will be progressed separately. Arle Court Park and Ride (now known as Arle Court Transport Hub) will provide a more integrated transport network by enabling opportunities to switch to more sustainable transport modes around Cheltenham. The improvements will have a real focus on sustainable transport and providing high quality alternatives to car use.
                    </p>
                    <p>
                        Please check our <a target="_blank" href="https://www.gloucestershire.gov.uk/highways/major-projects/"><b>Major Projects site</b></a> for future progress updates on Arle Court Transport Hub. 

                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Tables from "@/components/customPages/components/Table.vue";
export default {
    name: "site-accordionStatic",
    components: {
    Tables
  },
    props: {
        data: Array,
        headerField: String,
        contentField: String,
    },
    mounted() {
        this.$nextTick(() => {
            let elems = document.querySelectorAll('.collapsible');
            let instances = M.Collapsible.init(elems, {});
        });
    },
    methods: {
        getHeaderField(item) {
            return item.properties[this.headerField];
        },
        getContentField(item) {
            return item.properties[this.contentField];
        }
    }
};
</script>
