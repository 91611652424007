<template>
  <div class="site-view" v-if="pageData">
    <section class="site-text">
      <div class="container">
        <div class="row mb-0">
          <div class="col l12">
            <h1>{{ pageData.properties.pageTitle }}</h1>
            <div v-html="pageData.properties.pageDescription"></div>
          </div>
        </div>
      </div>
    </section>

    <SiteAccordion :data="faqItems" :headerField="'question'" :contentField="'answer'" />

    <SiteFooter />
  </div>
</template>

<script>
import firebase from "firebase";
import Settings from "@/mixins/AppSettings.js";
import { mapState, mapGetters } from "vuex";
import Logo from "@/assets/images/heathrow/logo.png";
import Branding from "@/components/Branding";
import SiteAccordion from "@/components/customPages/components/accordion.vue";
import SiteFooter from "@/components/customPages/components/footer.vue";
import _ from "lodash";

export default {
  name: "faqPage",
  components: {
    Branding,
    SiteAccordion,
    SiteFooter,
  },
  methods: {},
  computed: {
    ...mapState(["entities", "entityTypes"]),
    ...mapGetters(["user", "allEntityTypes", "allEntities"]),
    pageData() {
      if (this.$route.params.id != undefined) {
        return this.allEntities.find(
          (item) => item.docId == this.$route.params.id
        );
      } else {
        return this.allEntities.find(
          (item) => item.type == this.$route.params.templateName
        );
      }
    },
    faqItems() {
      if (this.pageData) {

        let faqItems = this.allEntities.filter((item) => item.type == "faqitem");

        let orderedFaqItems = _.orderBy(faqItems,"properties.order")

        return orderedFaqItems
        //const vm = this;
        // let array = vm.pageData.children.faqitem;

        
        // return array.map(function (docID) {
        //   return vm.allEntities.find((item) => item.docId == docID);
        // });

      } else {
        return [];
      }
    },
  },
  watch: {
    $route(to, from) {
      this.activeInstance = to.params.root;
    },
  },
};
</script>
