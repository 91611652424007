<template>
  <div class="site-view questionnaire" >
    <section class="site-text">
      <div class="container">
        <div class="row mb-0">
            <div class="col l12">
                <h1 v-if="pageData">{{ pageData.properties.pageTitle }}</h1>
                <div v-show="!thankYou" v-html="pageData.properties.pageDesc" v-if="pageData"></div>
            </div>
        </div>
      </div>
    </section>

    <section class="site-questions" v-if="thankYou == false">
      <div class="container">
        <div class="row" v-if="feedbackCountNew == 0">
          <div class="col s12 m12 l12">
            <div class="info-msg">
              <i class="material-icons">info_outline</i>
              <p>
                You have not selected any schemes to leave feedback on. Please
                go back to map and select the scheme's you would like to leave
                your opinion on before submitting your feedback.
              </p>
            </div>
          </div>
        </div>
        <div class="row" v-if="errors.length > 0">
          <div class="col s12 m12 l12">
            <div class="info-msg">
              <i class="material-icons">error_outline</i>
              <p><strong>Error:</strong></p>
              <p>
                Please fill in the following fields before submitting the
                feedback form.
              </p>
              <ul>
                <li v-for="(e, i) in errors" :key="i" v-html="e"></li>
              </ul>
            </div>
          </div>
        </div>
        <div clulass="row">
            <div class="col s12 m12 l12">
                <ul class="collapsible site-collapsible" ref="collapsible">
                
                <li
                    v-for="(item, index) in formFields"
                    :key="index"
                    v-show="item.group.show"
                    >
                    <!-- <div class="feedback-header" v-if="item.group.slug != 'extra'">
                        {{ item.group.name }}
                        <span><i class="material-icons">expand_more</i></span>
                    </div> -->
                    <div class="feedback-body">
                      <div class="fields">
                        <div v-for="field in item.questions" :key="field.name">
                          
                            <!-- Label -->
                            <div v-if="field.type == 'label'" class="input-field">
                                <label :for="field.name" v-html="field.displayName" ></label>
                            </div>
                          
                            <!-- String -->
                            <div v-if="field.type == 'string'" 
                              class="input-field site-string-field"
                              :class="field.name"
                              >
                                <label :for="field.name">{{ field.displayName }}: </label>
                                <span class="required" v-if="field.requiredField">*</span>
                                <input :id="field.name" type="text" v-model="field.value"/>
                            </div>

                          <!-- Dropdown -->
                          <div
                            v-if="field.type == 'dropdown'"
                            class="input-field site-dropdown-field"
                          >
                            <label :for="field.name">{{ field.displayName }}</label>
                            <span class="required" v-if="field.requiredField"
                              >*</span
                            >
                            <select class="browser-default" v-model="field.value">
                              <option
                                v-for="option in field.values"
                                :key="option.index"
                                :value="option.value"
                              >
                                {{ option.name }}
                              </option>
                            </select>
                          </div>
                          <!-- Textarea -->
                          <div
                            v-if="field.type == 'textarea'"
                            class="input-field site-textarea-field"
                          >
                            <label :for="field.name">{{ field.displayName }}</label>
                            <span class="required" v-if="field.requiredField"
                              >*</span
                            >
                            <textarea
                              :id="field.name"
                              type="text"
                              v-model="field.value"
                            ></textarea>
                          </div>
                          <!-- Radio -->
                          <div
                            v-if="field.type == 'radio'"
                            class="input-field site-radio-field"
                            :class="field.name"
                          >
                            <label :for="field.name">{{ field.displayName }}</label>
                            <span class="required" v-if="field.requiredField"
                              >*</span
                            >
                            <div class="radio t_caption">
                              <div
                                v-for="(item, index) in field.values"
                                v-bind:key="index"
                              >
                                <label>
                                  {{ item.name }}
                                  <input
                                    type="radio"
                                    :name="field.name"
                                    :value="item.value"
                                    class="propertyLand"
                                    :id="field.name + '_' + item.value"
                                    v-model="field.value"
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <!-- Checkbox -->
                          <div v-if="field.type == 'checkbox'" class="input-field">
                            <label :for="field.name">{{ field.displayName }}</label>
                            <span class="required" v-if="field.requiredField"
                              >*</span
                            >
                            <span class="helper-icon" v-if="field.helpField">
                              <i
                                class="material-icons"
                                @click="toggleHelper(field.name)"
                                >info_outline</i
                              >
                            </span>

                            <div
                              class="helper-msg"
                              :ref="field.name"
                              v-if="field.helpField"
                            >
                              <div
                                class="card-panel teal"
                                v-html="field.helpField"
                              ></div>
                            </div>

                            <div
                              :id="field.name + '_check'"
                              class="checkbox t_caption"
                            >
                              <label
                                class="checkbox-container"
                                v-for="item in field.values"
                                v-bind:key="item.value"
                              >
                                {{ item.name }}
                                <input
                                  type="checkbox"
                                  :id="item.value + '_check'"
                                  :value="item.value"
                                  v-model='checkboxVals'
                                />
                                <span class="checkmark" :id="item.value"></span>
                              </label>
                            </div>
                          </div>
                          <!-- Rank-->

                          <div v-if="field.type == 'rank'" class="input-field">
                            <label class="rankLabel" :for="field.name">{{
                              field.displayName
                            }}</label>
                            <div class="rank">
                              <div>{{ field.values.minDesc }}</div>
                              <div>{{ field.values.maxDesc }}</div>
                            </div>
                            <vue-slide-bar
                              v-model="sliderWithLabel.value"
                              :data="sliderWithLabel.data"
                              :range="sliderWithLabel.range"
                              @callbackRange="callbackRange"
                            >
                              <template slot="tooltip" slot-scope="tooltip">
                                <img
                                  src="static/vue-slide-bar/rectangle-slider.svg"
                                />
                              </template>
                            </vue-slide-bar>
                            <vue-range
                              class="rankBar"
                              :bar-height="3"
                              :step="1"
                              :min="field.values.minValue"
                              :max="field.values.maxValue"
                              v-model="field.value"
                              :handleValue="field.value"
                              :range="vueSliderOptions.range"
                            >
                              <div class="rankStart" slot="start">
                                {{ field.values.minValue }}
                              </div>
                              <div class="rankEnd" slot="end">
                                {{ field.values.maxValue }}
                              </div>
                            </vue-range>
                          </div>
                        </div>
                      </div>
                    </div>

                <div v-for="field in item.questions" :key="field.name">
                  <div
                    v-if="field.type == 'label' && item.group.slug == 'extra'"
                    class="input-field"
                  >
                    <label :for="field.name" v-html="field.displayName"></label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col s12 m12 l12">
            <div class="buttons">
              <button class="btn btn-secondary" @click.prevent="goBack()">
                Back to Map
              </button>
              <!-- <button
                        class="btn btn-secondary"
                        @click.prevent="addMoreFeedback()"
                        :disabled="activateFeedbackButtons"
                        >Add and continue</button> -->
              <button id="submit-feedback-button" class="btn btn-primary" @click.prevent="submitFeedback()">
                <span class="site-loading" v-show="loading"
                  ><img src="@/assets/images/Rolling-1s-50px.gif" alt=""
                /></span>
                <span :class="{ hideText: loading }">Submit Feedback</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="site-questions" v-show="!thankYou">
      <div class="container">
        <div class="row">
          <div class="col s12 m12 l12">
            <div class="success-msg">
            
              <p>Any surveys received after 23.59 on 25 November 2020 may not be accepted</p>
              <p>*To view the M5 Junction 10 Improvements Scheme Privacy Statement and further information about your rights under the Data Protection Act 2018 please visit: <a href="www.gloucestershire.gov.uk/highways/highways-privacynotices/">Privacy notices</a> </p>
              <p>*To exercise your rights under the Data Protection Act 2018, please contact: Data Protection Officer, Information Management Service, Shire Hall, Westgate Street, Gloucester, GL1 2TG or by e-mail at: Managemyrequests@ gloucestershire.gov.uk *Gloucestershire County Council is registered as a data controller with the Information Commissioner’s Office (ICO). Our registration number is Z7334901.
To request more information about this survey, please contact: M5Junction10@atkinsglobal.com,
or 01452 42 62 62 (Mon – Fri 8:30am to 4:30pm).</p>

            </div>
          </div>
        </div>   
        </div>
    </section> -->
    <section class="site-questions" v-show="thankYou">
      <div class="container">
        <div class="row">
          <div class="col s12 m12 l12">
            <div class="success-msg">
              <p class="large">Thank you for completing this survey.</p>
              <!-- <p>Any surveys received after 23.59 on 25 November 2020 may not be accepted</p>
              <p>*To view the M5 Junction 10 Improvements Scheme Privacy Statement and further information about your rights under the Data Protection Act 2018 please visit: <a href="www.gloucestershire.gov.uk/highways/highways-privacynotices/">www.gloucestershire.gov.uk/highways/highways-privacynotices/</a> </p>
              <p>*To exercise your rights under the Data Protection Act 2018, please contact: Data Protection Officer, Information Management Service, Shire Hall, Westgate Street, Gloucester, GL1 2TG or by e-mail at: Managemyrequests@ gloucestershire.gov.uk *Gloucestershire County Council is registered as a data controller with the Information Commissioner’s Office (ICO). Our registration number is Z7334901.
To request more information about this survey, please contact: M5Junction10@atkinsglobal.com,
or 01452 42 62 62 (Mon – Fri 8:30am to 4:30pm).</p> -->

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 m12 l12">
            <div class="buttons">
              <button
                class="btn btn-secondary"
                @click.prevent="clear()"
                v-show="thankYou"
              >
                Back to Map
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <SiteFooter />
  </div>
</template>

<script>
//import VueSlideBar from 'vue-slider-bar'

import "vue-slider-component/theme/default.css";
import Apicall from "@/mixins/Apicall";
import firebase from "firebase";
import Settings from "@/mixins/AppSettings.js";
import { mapState, mapGetters, mapActions } from "vuex";
import Logo from "@/assets/images/heathrow/logo.png";
import Branding from "@/components/Branding";
import { mapFields } from "vuex-map-fields";
import vueRange from "@/components/Range";
import SiteFooter from "@/components/customPages/components/footer.vue";

// we use this to strip non-sensible values from DOM ids
let validDomIdChars = /[^a-zA-Z0-9-_]/g;

export default {
  name: "feedback",
  components: {
    Branding,
    vueRange,
    SiteFooter,
  },
  data() {
    return {
      grpdConfirm: false,
      feedbackSucess: false,
      loading: false,
      errors: [],
      type: undefined,
      sliderWithLabel: {
        value: 45,
        data: [15, 30, 45, 60, 75, 90, 120],
        range: [
          { label: "15 mins" },
          { label: "30 mins", isHide: true },
          { label: "45 mins" },
          { label: "1 hr", isHide: true },
          { label: "1 hr 15 mins" },
          { label: "1 hr 30 mins", isHide: true },
          { label: "2 hrs" },
        ],
        rangeValue: {},
      },
      vueSliderOptions: {
        height: 15,
        dotSize: 32,
        clickable: false,
        tooltip: "always",
        processStyle: {
          backgroundColor: "#9e9e9e",
        },
        dotStyle: {
          backgroundColor: "#fc3",
        },
      },
      formFields: [],
      checkboxVals: [],
      types: [],
      parent: this.$route.params.id,
      mapInteractions: [],
      listOfFiles: [],
      uploadList: [],
      uploadobj: {},
      deleteList: [],
      currentFileItem: undefined,
      deleteEnabled: false,
      singleItem: false,
      fData: [],
      field: {},
      // singleId:[]
    };
  },

  computed: {
    ...mapState([
      "entities",
      "entityTypes",
      "feedbackData",
      "feedbackCountNew",
      "feedbackQuestions",
    ]),
    ...mapGetters([
      "user",
      "entityByDocId",
      "allEntityTypes",
      "thankYou",
      "allEntities",
    ]),
    pageData() {
      return this.allEntities.find(
        (item) => item.type == this.$route.params.templateName
      );
    },

    feedbackEntity() {
      return this.entityTypes.find((item) => item.type == "feedback");
    },

    currentSlug() {
      if (this.$route.params.id != undefined) {
        return this.entities.find(
          (item) => item.docId == this.$route.params.id
        );
      }
    },
  },

  async mounted() {
    let results = await Apicall.getfirebaseCollectionAll("entityTypes");
    if (this.entities.length == 0) {
      await this.$store.dispatch("populateEntities");
    }

    let feedbackEntityType = results.find((item) => item.type == "feedback");

    if (this.feedbackData.length != 0) {
      this.addMoreFeedback();
    } else {
      this.loading = false;
      this.feedbackSucess = false;
      this.createQuestionsArray(feedbackEntityType);
      if (this.$route.params.id != undefined) {
        this.addMoreFeedback();
      }
    }

    this.$nextTick(() => {
      this.initCollapisable();
    });
  },

  methods: {
    ...mapActions(["setFeedbackData", "setThankYouState"]),

    initCollapisable() {
      let elems = document.querySelectorAll(".collapsible");
      const instances = M.Collapsible.init(elems, {
        accordion: false,
      });
    },

    submitFeedback() {
      this.loading = true;
      var newArr = {};
      this.errors = [];
      if (this.formFields) {
           let properties = {};
      let errors = [];

      let name = "";
      let state = "";
      let createdBy = "";

      let allQuestions = []
      
        for (let group of this.formFields) {
          for (let field of group.questions) {
         
              if (field.type == "label") {
                properties[field.name] = "";
              } else if (field.name == "name") {
                name = field.value;
              } else if (field.name == "parent") {
                parent = field.value;
              } else if (field.name == "state") {
                state = field.value;
              } else if (field.name == "createdBy") {
                createdBy = field.value;
              } else {
                if (field.type == "color" && field.value.hex) {
                  properties[field.name] = field.value.hex;
                } else {
                  properties[field.name] = field.value;
                }
              }
          }
        }

        //   for (let field of group.questions) {
         
        //       if (field.type == "label") {
        //         properties[field.name] = "";
        //       } else if (field.name == "name") {
        //         name = field.value;
        //       } else if (field.name == "parent") {
        //         parent = field.value;
        //       } else if (field.name == "state") {
        //         state = field.value;
        //       } else if (field.name == "createdBy") {
        //         createdBy = field.value;
        //       } else {
        //         if (field.type == "color" && field.value.hex) {
        //           properties[field.name] = field.value.hex;
        //         } else {
        //           properties[field.name] = field.value;
        //         }
        //       }
        //   }

        properties["dateSubmitted"] = new Date().toUTCString();


        // //console.log(this.formFields);
        // debugger;
        // this.formFields.forEach(async (index, value) => {
        //   let key = index.group.slug;
        //   if (index.group.show == true) {
        //     let fieldBulider = {};
        //     index.questions.forEach(async (question, value) => {
        //       if (
        //         question.type == "string" ||
        //         question.type == "textarea" ||
        //         question.type == "dropdown" ||
        //         question.type == "radio"
        //       ) {
        //         //checking required or not to have error message
        //         if (
        //           question.requiredField == true &&
        //           (question.value == "" || question.value == undefined)
        //         ) {
        //           this.errors.push(
        //             "<b>" + question.displayName + "</b> is required"
        //           );
        //         } else {
        //           fieldBulider[
        //             this.questionString(question.displayName, index.group.name)
        //           ] = question.value;
        //         }
        //       } else if (
        //         question.type == "checkboxbutton" ||
        //         question.type == "checkbox" ||
        //         question.type == "rank"
        //       ) {
        //         fieldBulider[question.displayName] = question.value;
        //       } else if (question.type == "label") {
        //         //do nothing
        //       } else {
        //         fieldBulider[question.displayName] = "";
        //       }
        //     });
        //     console.log(fieldBulider);
        //     newArr[key] = fieldBulider;
        //   }
        // });
        // console.log(newArr);
        // newArr["user"] = this.user;
        if (this.errors.length != 0) {
          this.loading = false;
          return;
        } else {

        

            properties
          this.$store.dispatch("newSubmitFeedback", properties);
          this.loading = false;
        }
      } else {
        console.log("no feedback data");
      }
    },

    clear() {
      this.fData = [];
      this.$store.commit("setFeedbackData", "");
      this.loading = false;
      this.feedbackSucess = false;
      this.$store.commit("setThankYouState", false);
      this.$router.push("/map");
    },
    //go back to map
    goBack() {
      this.$router.push("/map");
    },
    questionString(question, groupName) {
      console.log(question, groupName);
      var regex = /\[.*?PLACEHOLDER.*?\]/gi;
      return question.replace(regex, groupName);
    },
    createQuestionsArray(feedbackEntityType) {
      if (feedbackEntityType) {
        let feedbackGroups = _.orderBy(
          feedbackEntityType.fieldGroups,
          ["order"],
          ["asc"]
        );
        let feedbackQuestions = feedbackEntityType.fields;

        let feedbackDataArray = [];

        feedbackGroups.forEach((group) => {
          let questions = [];
          let actualQuestion = "";
          for (let field in feedbackQuestions) {
          
            let defaultValue = undefined;
            if (feedbackQuestions[field].group.includes(group.slug)) {
              questions.push({
                displayName: this.questionString(
                  feedbackQuestions[field].displayName,
                  group.name
                ),
                name:field,
                order: feedbackQuestions[field].order,
                type: feedbackQuestions[field].type,
                value: defaultValue,
                values: feedbackQuestions[field].values,
                requiredField: feedbackQuestions[field].requiredField,
                visible: feedbackQuestions[field].visible,
              });
            }
          }

          feedbackDataArray.push({
            order: group.order,
            group: group,
            questions: _.orderBy(questions, ["order"], ["asc"]),
          });
        });
        this.setFeedbackGroupInStore(feedbackDataArray);
        // this.formFields = feedbackDataArray;
      }
    },
    //remove the feedback from the list
    removeFeedback(slug) {
      var feedbackData = this.feedbackData;

      if (feedbackData.length != 0) {
        Object.keys(feedbackData).forEach((key, index) => {
          if (feedbackData[key].group.slug == slug) {
            feedbackData[key].group.show = false;
          }
        }, feedbackData);
        this.setFeedbackGroupInStore(feedbackData);
      } else {
      }
    },
    //add Feedback group in store
    addFeedbackGroupsToStore(val) {
      var feedbackEntity = this.feedbackEntity;
      let slug = this.currentSlug;

      if (this.feedbackData.length != 0) {
        Object.keys(this.feedbackData).forEach((key, index) => {
          if (this.feedbackData[key].group.slug == slug.slug) {
            if (val == "add") {
              this.feedbackData[key].group.show = true;
            }
          }
        }, this.feedbackData);
        console.log("add/remove", val, this.feedbackData);
        this.setFeedbackGroupInStore(this.feedbackData);
      } else {
        if (this.feedbackEntity) {
          Object.keys(feedbackEntity.fieldGroups).forEach((key, index) => {
            if (feedbackEntity.fieldGroups[key].group.slug == slug.slug) {
              feedbackEntity.fieldGroups[key].group.show = true;
            }
          }, feedbackEntity.fieldGroups);

          this.setFeedbackGroupInStore(this.feedbackEntity.fieldGroups);
        }
      }
    },

    //storing feedback Group information to store
    setFeedbackGroupInStore(fieldGroups) {
      let feedbackGroup = _.orderBy(fieldGroups, ["order"], ["asc"]);
      this.formFields = fieldGroups;
      this.$store.commit("setFeedbackData", fieldGroups);
    },
    addMoreFeedback() {
      this.$store.commit("setThankYouState", false);
      if (this.$route.params.id != undefined) {
        this.addFeedbackGroupsToStore("add");
      } else {
        if (this.feedbackData.length != 0) {
          this.setFeedbackGroupInStore(this.feedbackData);
        } else {
          this.createQuestionsArray(this.feedbackEntity);
          //   if(this.feedbackEntity){
          //     this.setFeedbackGroupInStore(this.feedbackEntity.fieldGroups)
          //   }
        }
      }
    },

    //add all questions on all tabs
    feedbackFields(groupSlug) {
      if (this.feedbackEntity) {
        let feedbackEntity = this.feedbackEntity;
        let feedbackFields = [];

        Object.keys(feedbackEntity.fields).forEach((key, index) => {
          if (feedbackEntity.fields[key].group.includes(groupSlug)) {
            feedbackFields.push(feedbackEntity.fields[key]);
          }
        });

        return feedbackFields;
      } else {
        return [];
      }
    },
  },
  watch: {
    $route(to, from) {
      this.activeInstance = to.params.root;
    },
    fData: function (fData) {
      this.fData = fData;
    },
    "$store.state.feedbackData": function () {
      console.log("TESTING", this.$store.state.feedbackData);
      let feedbackData = this.$store.state.feedbackData;
      // let completeQuestionArray={}

      // Object.keys(feedbackData).forEach((key, index) => {
      // 	  completeQuestionArray = this.feedbackFields(feedbackData[key].slug)
      // 	  feedbackData[key].questions=completeQuestionArray
      // });
      this.formFields = feedbackData;
      this.$store.commit("setFeedbackData", feedbackData);
    },
    feedbackGroups(val) {
      this.$nextTick(() => {
        let elems = document.querySelectorAll(".collapsible");
        const instances = M.Collapsible.init(elems, {
          accordion: false,
        });
      });
    },
  },
};
</script>
<style scoped>

#submit-feedback-button:focus{
  border: 3px white dashed;
}

</style>
