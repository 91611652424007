<template>
  <div class="site-view" >
     <section v-if="!pageData" class="site-2col">
          <div class="container">
            <div class="row mb-0">
                <div class="fixedCentre">
                    <h2>Loading please wait..</h2>
                </div>
            </div>
      </div>
    </section> 
    
    <section v-if="pageData" class="hero_banner">
        <full-page ref="fullpage" :options="fullpage_options" id="fullpage">
            <div class="section fp-auto-height-responsive" style="padding:0">
                <div class="each-slide slider1" >
                <!-- <div class="each-slide"  :style="{'background-image': 'url(' + require('@/assets/images/mobile/slideimage1.png') + ')'}"> -->
                   
                    <div class="content">
                        <div class="container">
                            <div class="row">
                                 <div class="col s12 m12 l12 mobile show-on-med-and-down hide-on-large-only">
                                    <div v-html="pageData.properties.page1"></div>
                                </div>
                              
                               
                                <div class="col s12 m5 l5 hide-on-med-and-down show-on-large">
                                    <div v-html="pageData.properties.page1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="embed-container">
                        <div class="intro">
                            <img src="@/assets/images/mobile/slideimage1large.png" class="show-on-med-and-down hide-on-large-only" style="width:100%;">
                           
                            <video plays inline muted loop data-autoplay 
                                poster="@/assets/images/GCC_Homepgae01.gif"
                                id="bg01"
                                class="hide-on-med-and-down show-on-large"
                            >
                            
                                <source
                                :src="pageData.properties.videoAsset1"
                                type="video/mp4"
                                />
                            </video>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="section fp-auto-height-responsive" style="padding:0">
                <div class="each-slide slider2">
                    <div class="embed-container">
                        <div class="intro">
                             <img src="@/assets/images/mobile/slideimage3large.png" class="show-on-med-and-down hide-on-large-only" style="width:100%;">
                            <video plays inline muted loop data-autoplay 
                                poster="@/assets/images/GCC_Homepgae02.gif"
                                id="bg02"
                               
                            >
                             <source
                                :src="pageData.properties.videoAsset2"
                                type="video/mp4"
                                 class="hide-on-med-and-down show-on-large"
                                />
                                 <!-- <source
                                src="@/assets/images/mobile/slideimage3large.png"
                                type="video/mp4"
                                class="show-on-med-and-down hide-on-large-only" style="width:100%;"
                                /> -->
                           
                            </video>
                        </div>
                    </div>
                    
                    <div class="content">
                        <div class="container">
                            <div class="row">
                                <div class="col s12 m12 l12 mobile show-on-med-and-down hide-on-large-only">
                                    <div v-html="pageData.properties.page2"></div>
                                </div>
                              
                                <div class="col s12 m4 l4 offset-l8 offset-m8 offset-s8 hide-on-med-and-down show-on-large">
                                    <div v-html="pageData.properties.page2"></div>
                                    <!-- <h2>M5 Junction 10 Improvements Scheme</h2>
                                    <p>This is a public consultation where we can gather your opinion to make informed decisions to the proposal. This is a public consultation where we can gather your opinion to make informed decisions to the proposal. </p>
                                    <br/>
                                    <p class="italic text-align-right">This is a public consultation where we can gather your opinion to make informed decisions to the proposal. This is a public consultation </p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- end of .each-slide-->
            </div>
            <div class="section fp-auto-height-responsive" style="padding:0">
                <div class="each-slide">
                    
                    <div class="content">
                        <div class="container">
                            <div class="row">
                                 <div class="col s12 m12 l12 mobile show-on-med-and-down hide-on-large-only">
                                    <div v-html="pageData.properties.page3"></div>
                                </div>
                                <div class="col s12 m6 l4 hide-on-med-and-down show-on-large">
                                    <div v-html="pageData.properties.page3"></div>
                                    <!-- <h1 class="primary-color mb-4">M5 Junction 10 Improvements Scheme</h1>
                                    <a href="#" class="btn">View the map</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="embed-container">
                        <div class="intro">
                             <img src="@/assets/images/mobile/slideimage2large.png" class="show-on-med-and-down hide-on-large-only" style="width:100%;">
                            <video plays inline muted loop data-autoplay 
                                poster="@/assets/images/GCC_Homepgae03.gif"
                                id="bg03"
                            >
                            <source
                                :src="pageData.properties.videoAsset3"
                                type="video/mp4"
                                />
                            </video>
                        </div>
                    </div>
                </div>   
            </div>
      </full-page>
        
    </section>
   
    
    
    <SiteFooter v-if="pageData" />
  </div>
</template>

<script>
import firebase from "firebase";
import Settings from "@/mixins/AppSettings.js";
import { mapState, mapGetters } from "vuex";
import Logo from "@/assets/images/heathrow/logo.png";
import Branding from "@/components/Branding";
import backgroundUrl from "@/assets/images/template/home/hero.jpg";
import backgroundUrl2 from "@/assets/images/template/home/questionnaire.jpg";
import Apicall from "@/mixins/Apicall";
import SiteFooter from "@/components/customPages/components/footer.vue";
import _ from "lodash";

// Full page JS
import VueFullPage from 'vue-fullpage.js'
import 'fullpage.js/dist/fullpage.css' // piping the css into the main css
import Vue from 'vue'
Vue.use(VueFullPage)

export default {
    name: "homePage", 
    components: {
        Branding,
        SiteFooter,
    },
    data() {
        return {
            fields: {
                name: "",
                email: "",
                address: "",
                postcode: "",
                telephone: "",
                message: "",
                checkedDoc: [],
            },
            backgroundUrl: backgroundUrl,
            backgroundUrl2: backgroundUrl2,    

            fullpage_options: {
                anchors: ['firstPage', 'secondPage', '3rdPage'],
                sectionsColor: ['#0054A4', '#0054A4', '#0054A4'],
                navigation: true,
                navigationPosition: 'right',
                // navigationTooltips: ['First page', 'Second page', 'Third and last page'],
                responsiveWidth: 1200, // breakpoint
                afterResponsive: function(isResponsive){

                }
            },

        };
    },
    computed: {
        ...mapState(["entities", "entityTypes"]),
        ...mapGetters(["user", "allEntityTypes", "allEntities"]),
        pageData() {
            if (this.$route.params.id != undefined) {
                return this.allEntities.find(
                    (item) => item.docId == this.$route.params.id
                );
            } else {
                return this.allEntities.find(
                    (item) => item.type == this.$route.params.templateName
                );
            }
        },
   
        // timelineInfo: function () {
        //     if (this.pageData.length !== 0 && this.pageData.children) {
        //         return _.orderBy(
        //             this.allEntities.filter((item) => {
        //                 return (
        //                     this.pageData.children.timelineItem.indexOf(item.docId) != -1
        //                 );
        //             }),
        //             "properties.date",
        //             "asc"
        //         );
        //     }
        // },
    }, // end of computed

    async mounted() {
        if (this.entities.length == 0) {
            //debugger
            await this.$store.dispatch("populateEntities");
        }
        // this.populateTimeline();
    },
    
    methods: {
        async submitForm(event) {
            var messageBody = "";

            for (let item in this.fields) {
                messageBody += "<p>" + item + ":" + this.fields[item] + "</p>";
            }

            let bodyData = {
                subject: "Contact Information M5 Contact Form",
                messageBody: messageBody,
            };
            let results = await Apicall.submitForm(bodyData);
            if (results.status == "200") {
                M.toast({
                    html: "Message sent, thank you.",
                    classes: "blue",
                });

                this.fields = {
                name: "",
                email: "",
                address: "",
                postcode: "",
                telephone: "",
                message: "",
                checkedDoc: [],
                };
                console.log("Submitted");
            } else {
                M.toast({
                    html: "Something went wrong. Please try again",
                    classes: "error",
                });
                console.log("Please try submitting again");
            }
        },
    },
    
    watch: {
        $route(to, from) {
            this.activeInstance = to.params.root;
        },
        pageData(to, from) {
            // this.populateTimeline();
        },
    },
};
</script>
